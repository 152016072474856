import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";

import FaqforCC from "../FaqforCC";
import { accordionDataBranding } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";


const Branding = () => {
  return (
    <>
           <Helmet>
              <title>Creative Branding Agency | No1 Branding Agency In Duabi</title>
              <meta
               name="description"
              content="Elevate your brand with expert branding services from The Impression in Dubai. Build a strong identity and connect with your audience effectively."
                 />
           </Helmet>
      <SectionHeading
        mainTitle="Branding"
        caption="Building Powerful Brand Identities"
        subtitle="Transforming Ideas into Iconic Brands"
        para="We craft distinctive brand identities that resonate deeply with your target audience. From strategic positioning to compelling messaging, our approach ensures your brand stands out in a competitive market."
      ></SectionHeading>

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle=" What We Offer in Branding"
            para="Comprehensive Solutions for Brand Excellence"
          >
            <Box>
              <ul>
                <li>
                  <h4>Strategic Positioning:</h4> Defining your brand’s unique
                  value and place in the market.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Visual Identity Design:</h4> Creating logos, color
                  palettes, and typography that represent your brand’s essence.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Messaging Development:</h4> Crafting compelling taglines
                  and narratives to strengthen your brand voice.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Brand Guidelines:</h4> Ensuring consistency across all
                  channels for a cohesive identity.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />

      <Div className="row">
        <SectionHeading
          caption="Elevating Your Brand to New Heights"
          para="Why Choose The Impression for Branding?"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Tailored Approach:</h4> Customized solutions that reflect
                your brand’s personality and goals.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Creative Expertise:</h4> A team of designers and strategists
                with a proven track record in branding.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>End-to-End Support:</h4> From ideation to implementation, we
                manage every step of the process.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Localized Insights:</h4> Expertise in Dubai’s diverse market
                to ensure your brand connects with local and global audiences.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Multi-Industry Experience:</h4> Success across industries,
                including hospitality, e-commerce, healthcare, and technology.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="100" md="80" />

      {/* Testimonials */}

      {/* <Div className="container">
        <SectionHeading
          subtitle="FAQs About Branding Service"
          para="Your Questions About Branding Answered"
        />
        <Div className="row">
        
          <Div
            className={
              accordionDataBranding.length > 7 ? "col-xl-5 col-lg-6" : ""
            }
          >
            <Accordion name="Branding" />
          </Div>

          {accordionDataBranding.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="Branding" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>
  );
};

export default Branding;
