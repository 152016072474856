// export const pageTitle = (title) => {
//   // return ( document.title = title + " - Creative Agency");
//    return ( document.title = "The Impression");
// }
export const pageTitle = (title) => {
  const currentURL = window.location.pathname;
  const excludedURLs = [
    '/service/content-creation', 
    '/service/digital-media-buying-agency', 
    '/service/social-media-management', 
    '/service/360-campaigns', 
    '/service/lead-generation-and-acquisition', 
    '/service/research-and-development', 
    '/service/character-creation-2d-and-3d', 
    '/service/branding', 
    '/service/programmatic-advertising', 
    '/service/ui-ux-design', 

  ];

  if (excludedURLs.includes(currentURL)) {
    return;
  }
  return ( document.title = "The Impression");
};
