import React from 'react'
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionDataDigitalMedia } from '../../Accordion/AccordionData'
import { Helmet } from "react-helmet";

const DigitalStatergy = () => {
    const colordata = useSelector((state) => state && state.colordata)



  return (
    <>
         <Helmet>
            <title>Digital Media Buying Agency | The Impression</title>
            <meta
              name="description"
              content="Maximize your ROI with The Impression, a leading digital media buying agency in Dubai. Expert ad placements to reach your audience and drive results."
            />
          </Helmet>
      <SectionHeading
        mainTitle="Digital Strategy and Media Buying"
        caption="Expert Digital Strategy and Media Buying in Dubai"
        para="We specialize in crafting innovative digital strategies and delivering precise media buying solutions for businesses in Dubai. With a deep understanding of the local market, we help brands reach their audience effectively, ensuring maximum impact and measurable results.
              "
      ></SectionHeading>

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            caption="Data-Driven Digital Strategy and Media Buying Services"
            para="We specialize in crafting innovative digital strategies and delivering precise media buying solutions for businesses in Dubai. With a deep understanding of the local market, we help brands reach their audience effectively, ensuring maximum impact and measurable results.
              "
          >
            

            {/* <h2
                      className="cs-m0"
                      style={{ color: colordata == "light" ? "#000" : "" }}
                    >
                      Driving brand futures through innovation, individuality,
                      and social responsibility.
                    </h2>
                    <Spacing lg="15" md="15" />
                    <p
                      className="cs-m0"
                      style={{ color: colordata == "light" ? "#000" : "" }}
                    >
                      The Impression is at the forefront of digital evolution.
                      We're committed to evolving international businesses,
                      forging impactful partnerships, and delivering growth
                      across all sectors.
                    </p>
                    <Spacing lg="30" md="30" />
                    <Div className="cs-separator cs-accent_bg"></Div>
                    <Spacing lg="25" md="40" /> */}
          </SectionHeading>
          
        </Div>
        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>

      <Div className='row'>
      <Spacing lg="30" md="20" />
            <div>
              <h3 >A. Digital Strategy Development</h3>
              <p>
                We create tailored strategies that transform your business goals
                into actionable plans, ensuring you stay ahead in a competitive
                market.
              </p>
              <ul>
                <li>
                  <h4>Competitor Analysis:</h4> Identify key strengths
                  and gaps in your industry to refine your positioning.
                </li>
                <li>
                  <h4>Audience Segmentation:</h4> Target the right
                  customers with personalized messaging and precise audience
                  insights.
                </li>
                <li>
                  <h4>Content Strategy:</h4> Align your digital content
                  with your audience’s preferences to enhance engagement and
                  conversions.
                </li>
              </ul>
            </div>
            <Spacing lg="30" md="20" />

            <div>
              <h3>B. Media Buying</h3>
              <p>
                Our expert media buying services ensure your ads are seen by the
                right people at the right time across multiple platforms.
              </p>
              <ul>
                <li>
                  <h4>Platform Selection:</h4> Choose the best
                  platforms, such as Google, Meta, and TikTok, to achieve your
                  goals.
                </li>
                <li>
                  <h4>Real-Time Optimization:</h4> Continuously refine
                  ad performance to maximize efficiency and reach.
                </li>
                <li>
                  <h4>Budget Management:</h4> Strategically allocate
                  resources to deliver maximum return on investment.
                </li>
              </ul>
            </div>
            <Spacing lg="30" md="20" />

            <div>
              <h3>C. Performance Tracking & Analytics</h3>
              <p>
                We believe in the power of data to drive decisions. Our
                performance tracking ensures your campaigns are optimized for
                success.
              </p>
              <ul>
                <li>
                  <h4>Advanced Analytics:</h4> Gain insights into
                  campaign effectiveness with detailed metrics and dashboards.
                </li>
                <li>
                  <h4>Refinement Strategies:</h4> Adapt and adjust
                  campaigns based on real-time data to achieve optimal results.
                </li>
                <li>
                  <h4>Performance Reviews:</h4> Regular updates and
                  insights to keep you informed and in control of your
                  campaigns.
                </li>
              </ul>
            </div>
      </Div>


      <Div className="row">
        <SectionHeading
          subtitle="What Sets Us Apart in Digital Strategy and Media Buying"
          para="We specialize in crafting innovative digital strategies and delivering precise media buying solutions for businesses in Dubai. With a deep understanding of the local market, we help brands reach their audience effectively, ensuring maximum impact and measurable results.
              "
        >
          <dl>
            <dt>
              <h3>Local Expertise in the Dubai Market</h3>
            </dt>
            <dd>
              With a deep understanding of Dubai’s business landscape, we craft
              campaigns tailored to industries like hospitality digital lead
              generation, real estate growth campaigns, and e-commerce marketing
              services.
            </dd>

            <dt>
              <h3>Access to Advanced Tools and Platforms</h3>
            </dt>
            <dd>
              We leverage platforms such as Google, TikTok, and Meta for Google
              Ads for medical clinics, PPC for e-commerce websites, and SEO for
              insurance websites to ensure your brand achieves maximum
              visibility.
            </dd>

            <dt>
              <h3>Transparent Reporting and Communication</h3>
            </dt>
            <dd>
              Our detailed reports provide insights into campaign performance,
              from insurance client acquisition to restaurant customer
              acquisition, ensuring you stay informed every step of the way.
            </dd>

            <dt>
              <h3>Proven Track Record of Successful Campaigns</h3>
            </dt>
            <dd>
              Our results speak for themselves, with successful campaigns in
              luxury property promotion, best PPC for healthcare, and auto
              industry advertising, helping businesses achieve measurable
              growth.
            </dd>
          </dl>
        </SectionHeading>
      </Div>
      <Div className="row">
        <SectionHeading
          subtitle="Industries Benefiting from Our Media Buying Expertise"
          para="We have extensive experience delivering impactful results across a variety of industries:"
        >
         <dl>
  <div className="inline-dl">
    <dt>
      <h4>Real Estate : </h4>
    </dt>
    <dd>
      Expertise in real estate lead generation, Google Ads for real estate developers, and luxury real estate marketing.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Hospitality : </h4>
    </dt>
    <dd>
      Proven success in tourism promotion marketing, hotel digital lead generation, and restaurant branding strategies.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>E-commerce : </h4>
    </dt>
    <dd>
      Specialized in e-commerce advertising campaigns, SEO for online stores, and social media for e-commerce.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Retail : </h4>
    </dt>
    <dd>
      Targeted solutions for online product ads and customer acquisition for online stores.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Healthcare : </h4>
    </dt>
    <dd>
      Effective strategies in healthcare promotion campaigns, PPC for healthcare, and Google Ads for medical services.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Insurance : </h4>
    </dt>
    <dd>
      Comprehensive services in insurance PPC campaigns, insurance marketing strategy, and insurance client acquisition.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Automotive : </h4>
    </dt>
    <dd>
      Expertise in auto dealership ads, vehicle promotion agency campaigns, and automotive marketing strategies.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Education : </h4>
    </dt>
    <dd>
      Delivering results with education PPC advertising, SEO for online courses, and student recruitment ads.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>F&B (Food & Beverage) : </h4>
    </dt>
    <dd>
      Creating success with restaurant digital marketing, food delivery service marketing, and PPC for fast food chains.
    </dd>
  </div>
  <div className="inline-dl">
    <dt>
      <h4>Technology : </h4>
    </dt>
    <dd>
      Focused campaigns for SaaS customer acquisition ads, digital ads for software firms, and Google Ads for tech companies.
    </dd>
  </div>
</dl>

        </SectionHeading>
      </Div>
      <Spacing lg="100" md="80" />

     

      <>
        {/* <SectionHeading subtitle="Answers to Your Digital Strategy and Media Buying Questions"></SectionHeading>

        <Div className="row">
          <Div className={
                accordionDataDigitalMedia.length > 7
                  ? "col-xl-5 col-lg-6"
                  : ""
              }>
            <Accordion name="DigitalMedia" />
          </Div>

          {accordionDataDigitalMedia.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="DigitalMedia" isSecondColumn={true} />
            </Div>
          )}
        </Div> */}
      </>
    </>
  );
}

export default DigitalStatergy;

// back up testimonials


{/* <Div className="container">
<Div className="row">
  <Div className="col-xl-5 col-lg-6">
    <Div className="cs-image_layer cs-style1">
      <Div className="cs-image_layer_in">
        <img
          src="/images/about_img_4.jpeg"
          alt="About"
          className="w-100 cs-radius_15"
        />
      </Div>
    </Div>
    <Spacing lg="0" md="40" />
  </Div>
  <Div className="col-xl-5 offset-xl-1 col-lg-6">
    <SectionHeading title="Why Partner with Us?" subtitle="">
      <Spacing lg="30" md="20" />
      <p
        className="cs-m0"
        style={{ color: colordata == "light" ? "#000" : "" }}
      >
        Our approach is holistic and data-driven, ensuring that every
        strategy is grounded in real-world insights and tailored to your
        specific goals. We're not just consultants; we're your strategic
        partners, dedicated to navigating the complexities of the
        digital landscape together.
      </p>
      <Spacing lg="15" md="15" />
      <p
        className="cs-m0"
        style={{ color: colordata == "light" ? "#000" : "" }}
      >
        With us, you gain more than just a service provider; you gain
        allies committed to your success. Our track record speaks for
        itself: a history of transforming challenges into opportunities
        and businesses into benchmarks of their industries.
      </p>
      <Spacing lg="15" md="15" />
      <p style={{ color: colordata == "light" ? "#000" : "" }}>
        Choose us and let's contribute to your Long and Lasting
        IMPRESSION.
      </p>
      <Spacing lg="30" md="30" />
      <Div className="cs-separator cs-accent_bg"></Div>
      <Spacing lg="25" md="0" />
    </SectionHeading>
  </Div>
</Div>
</Div> */}