import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import parse from 'html-react-parser';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify";

export default function ContactPage() {
	const [contactdetails, setContactDetails] = React.useState([]);
	const [phonenumber, setPhonenumber] = React.useState();
	const [buttonview, setButtonview] = React.useState(false);
	const colordata = useSelector((state) => state && state.colordata)
	const [contactdetl, setContactdetl] = React.useState({ firstname: "", lastname: "", email: "", company: "", phonenumber: "", });
	const [mapview, setMapview] = React.useState('');
	const [errror, setError] = React.useState({ firstname: "", lastname: "", email: "", company: "", phonenumber: "", });
	const [serviceavlb, setServiceavlb] = React.useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	});
	pageTitle("Contact Us");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getContactDetails();
	}, []);

	const getContactDetails = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `admincontact/getAdmindetailsPublic`).then((res) => {
			setContactDetails(res.data.admindetails.contactdetails);
			setMapview(res.data.admindetails.contactdetails && res.data.admindetails.contactdetails?.length > 0 && res.data.admindetails.contactdetails[0].googlemap)
		}).catch((error) => {
			console.log(error, "error");
		});
	};


	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value });
		setError({ ...errror, [e.target.name]: "" });
	};
	const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked });
	};

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		if (!contactdetl.firstname) {
			setError({ ...errror, firstname: "This field is required" });
			return false;
		}
		if (!contactdetl.email) {
			setError({ ...errror, email: "This field is required" });
			return false;
		}
		if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: "Please enter valid email" });
			return false;
		}
		if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: "This field is required" });
			return false;
		}
		return true;
	};

	const handleClik = (data, index) => {
		const updatedList = contactdetails.map((item, i) => ({
			...item,
			selected: i === index,
		}));
		setContactDetails(updatedList);
		setMapview(data.googlemap);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate();
		if (valid == true) {
			setButtonview(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			};
			axios.post(process.env.REACT_APP_BASE_URL + "Enquiry/createEnquiry", body).then((res) => {
				setButtonview(false)
				setContactdetl({ firstname: "", lastname: "", email: "", company: "", phonenumber: "", });
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setButtonview(false)
				console.log(err);
			});
		}
	};
	function displayAddress() {
		var element = document.getElementById("showhover");
		element.classList.add("cs-style1 ");
	}
	function hideAddress() {
		var element = document.getElementById("showhover");
		element.classList.remove("cs-style1");
	}
	return (
		<Div className="contact_page_main">
			<PageHeading
				title="Contact Us"
				bgSrc="/images/ContactUs.jpg"
				// pageLinkText="Contact"
			/>
			<Spacing lg="80" md="40" />

			<Div className="container contact_page cs-shape_wrap_4">
				 <Div className="cs-page_heading_in centered-header"  style={{ display: "flex",flexDirection: "column",alignContent: "space-between",justifyContent: "flex-start",alignItems: "center",}}>
						<h2 className="light_pink">Contact</h2>
				</Div>
				<Div className="cs-shape_4"></Div>
				<Div className="cs-shape_4"></Div>
				<Div className="row">
					<Div className="col-lg-6">
						<SectionHeading
							title="Do you have a project <br/>in your mind?"
							subtitle="Getting Touch"
						/>
						<Spacing lg="55" md="10" />

						<Spacing lg="55" md="10" />


					</Div>
					<Div className="col-lg-6">
						<form className="row" >
							<Div className="col-sm-6">
								<label className="cs-primary_color">First Name*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.firstname}
									name="firstname"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.firstname}</p>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Last Name </label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.lastname}
									name="lastname"
									onChange={handleContactdetail}
								/>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Company</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.company}
									name="company"
									onChange={handleContactdetail}
								/>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Email*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.email}
									name="email"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.email}</p>
								<Spacing lg="20" md="20" />
							</Div>

							<Div className="col-sm-6">
								<label className="cs-primary_color">Phone Number*</label>
								<PhoneInput
									value={contactdetl.phonenumber}
									className="cs-form_field"
									defaultCountry="LB"
									onChange={(e) => {
										setContactdetl({ ...contactdetl, phonenumber: e });
										setError({ ...errror, phonenumber: "" });
									}}
								/>
								<p style={{ color: "red" }}> {errror.phonenumber}</p>
								<Spacing lg="20" md="20" />
							</Div>

							<h2 className={colordata == 'dark' ? "" : "contacttect"}>What services are you interested in?</h2>

							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="branding"
											checked={serviceavlb.branding}
											onChange={handleServicedetail}
										/>
									}
									label="Branding"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="campaigns_360"
											checked={serviceavlb.campaigns_360}
											onChange={handleServicedetail}
										/>
									}
									label="360 Campaigns"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="content_creation"
											checked={serviceavlb.content_creation}
											onChange={handleServicedetail}
										/>
									}
									label="Content Creation"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="digital_strategy_media_buying"
											checked={serviceavlb.digital_strategy_media_buying}
											onChange={handleServicedetail}
										/>
									}
									label="Digital Strategy & Media Buying"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="social_media_management"
											checked={serviceavlb.social_media_management}
											onChange={handleServicedetail}
										/>
									}
									label="Social Media Management"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="research_development"
											checked={serviceavlb.research_development}
											onChange={handleServicedetail}
										/>
									}
									label="Research & Development"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="data_mining"
											checked={serviceavlb.data_mining}
											onChange={handleServicedetail}
										/>
									}
									label="Data Mining"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="lead_generation"
											checked={serviceavlb.lead_generation}
											onChange={handleServicedetail}
										/>
									}
									label="Lead Generation"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="websites_applications"
											checked={serviceavlb.websites_applications}
											onChange={handleServicedetail}
										/>
									}
									label="BrandWebsites & Applicationsing"
								/>
							</Div>
							<Div className="col-sm-6">
								<FormControlLabel
									className="checkbox_div"
									control={
										<Checkbox
											defaultChecked
											name="advisory_consulting_services"
											checked={serviceavlb.advisory_consulting_services}
											onChange={handleServicedetail}
										/>
									}
									label="Advisory & Consulting Services"
								/>
							</Div>
						</form>
						<Div className="col-sm-12">
							{buttonview == false ?
								<button className="cs-btn cs-style1" onClick={(e) => handleSubmit(e)}>
									<span>Send Message</span>
									<Icon icon="bi:arrow-right" />
								</button> :
								<button className="cs-btn cs-style1">
									<span>Please wait...</span>
								</button>}
						</Div>
					</Div>

				</Div>

				<Spacing lg="40" md="10" />

				<Div className="row addresslist">
					<Div className="col-md-12 col-lg-8 col-xl-6">
					</Div>
					<Div className="col-md-12 col-lg-8 col-xl-6">
						<SectionHeading
							title="Address"
							subtitle=""
						/>
						<Spacing lg="40" md="10" />

						<Div className="row ">

							{contactdetails && contactdetails?.length > 0 && contactdetails.map((data, k) => (
								<>
									<Div className={'cs-section_heading cs-style1 mb-30  col-md-6 col-lg-6'}>
										<h4 className={data.selected === true ? 'addresstitle' : 'addresstitle'}>{parse(data.addresstype)}</h4>
										<ul className="cs-menu_widget cs-style1 cs-mp0">
											<li>
												{data && data.phone ? <span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
												{data && data.phone}
											</li>
											<li>
												{data && data.email ? <span className='cs-accent_color'><Icon icon="mdi:envelope" /></span> : ''}
												{data && data.email}
											</li>
											<li>
												{data && data.address ? <span cursor='pointer' onClick={() => handleClik(data, k)} className='cs-accent_color'><Icon cursor='pointer' onClick={() => handleClik(data, k)} icon="mdi:map-marker" /></span> : ''}
												{data && data.address}
											</li>
										</ul>
									</Div>
								</>
							))}
						</Div>
					</Div>

					<Spacing lg="0" md="0" />
				</Div>
			</Div>
			<Spacing lg="50" md="00" />

			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '& > *': { m: 1, }, }}>
				<ButtonGroup variant="outlined" aria-label="Basic button group" className='customTabs'>
					{contactdetails && contactdetails.map((data, k) => (
						<Button key={k} onClick={() => handleClik(data, k)} className={data.selected === true ? 'active_tabsnew' : ''} >{data.addresstype}</Button>
					))}
				</ButtonGroup>
			</Box>


			<Div className={colordata == "light" ? "cs-google_mapblack map_container" : "cs-google_map map_container"}>
				<div dangerouslySetInnerHTML={{ __html: mapview }} />
			</Div>
			{/* <Div className="col-lg-4 col-sm-6 mb-10">
						<Div className="cs-google_map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
								allowFullScreen
								title="Google Map"
							/>
						</Div>
					</Div> */}

			{/* <Spacing lg="50" md="40" /> */}
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</Div>
	);
}