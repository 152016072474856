import React from 'react';
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionDataCharacterCreation } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";


const CharacterCreation = () => {
    return (
      <>
       <Helmet>
           <title>Character Creation | 2D & 3D Character Creator</title>
          <meta
           name="description"
          content="Explore professional 2D & 3D character creation services at The Impression. Bring your ideas to life with stunning, custom-designed characters in Dubai."
             />
       </Helmet>
        <SectionHeading
          mainTitle="Character Creation"
          caption="Crafting Characters That Inspire and Connect"
          subtitle="Engaging Audiences with 2D and 3D Character Creation"
          para="We bring characters to life in both two and three dimensions, adding depth and personality to your brand’s storytelling. Our creations enhance emotional connections, boosting audience engagement and strengthening brand recall."
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              subtitle="What We Offer with Character Creation"
              para="Creating Brand Custom Characters and mascots"
            >
              <Box>
                <ul>
                  <li>
                    <h4>2D Character Design:</h4> Crafting visually appealing
                    and dynamic characters tailored to your brand identity.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>3D Modeling and Animation:</h4> Creating lifelike,
                    detailed characters that resonate with audiences across
                    platforms.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Story Integration:</h4> Embedding characters seamlessly
                    into narratives for consistent messaging.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Brand-Specific Personalities:</h4> Designing characters
                    that reflect your brand’s values and vision to leave a
                    lasting impact.
                  </li>
                </ul>
              </Box>
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="30" md="20" />

        <Div className="row">
          <SectionHeading
            caption=" Setting Your Brand Apart with Unique Characters"
            para="Why Choose The Impression for Character Creation?"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Creative Excellence:</h4> Expert designers dedicated to
                  crafting unique, emotionally engaging characters.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Innovative Technology:</h4> Utilizing advanced tools for
                  high-quality 2D designs and 3D animations.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Strategic Storytelling:</h4> Integrating characters into
                  narratives to enhance brand communication.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Cross-Industry Expertise:</h4> Proven success in
                  hospitality, e-commerce, and entertainment industries.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Localized Insights:</h4> Characters that resonate with
                  diverse audiences, particularly in the dynamic Dubai market.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        {/* Testimonials */}

        {/* <Div className="container">
          <SectionHeading subtitle="FAQs About 2D and 3D Character Creation"
          para = "Answers to Your Questions About Character Design"
            />
          <Div className="row">
            <Div
              className={
                accordionDataCharacterCreation.length > 7
                  ? "col-xl-5 col-lg-6"
                  : ""
              }
            >
              <Accordion name="CharacterCreation" />
            </Div>

            {accordionDataCharacterCreation.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="CharacterCreation" isSecondColumn={true} />
              </Div>
            )}
          </Div>
        </Div> */}
      </>
    );
}

export default CharacterCreation