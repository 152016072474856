import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/pagination';
import PhoneInput from 'react-phone-number-input';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";


export default function BlogDetailsPage() {
	const location = useLocation();
	const [contactdetl, setContactdetl] = React.useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "", comments: "" })
	const [errror, setError] = React.useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [buttonview, setButtonview] = React.useState(false);
	const blogDetails = location.state
	const params = useParams()
	pageTitle('Blog Details');
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setButtonview(true)
			let body = {
				"first_name": contactdetl.firstname,
				"last_name": contactdetl.lastname,
				"email": contactdetl.email,
				"phone_number": contactdetl.phonenumber,
				"company": contactdetl.company,
				"comments": contactdetl.comments,
				"blog": true
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry1', body).then((res) => {
				setButtonview(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "", comments: "" })
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				toast.success('Success')
			}).catch((err) => {
				setButtonview(false)
				console.log(err)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "", comments: "" })
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
			})
		}
	}

	return (
		<>
			{/* Start Page Heading Section */}
			<PageHeading
				title='Blog Details'
				bgSrc='/images/blog_details_hero_bg.jpeg'
				pageLinkText={params.blogDetailsId}
			/>
			 <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
			{/* End Page Heading Section */}

			{/* Start Blog Details */}
			<Spacing lg='80' md='40' />
			<Div className="container blog_details_page">
				<Div className="row">
					<Div className="col-lg-8">
						{/* Start Details Post Content */}
						<Div className="cs-post cs-style2">
							<Swiper
								navigation={true}
								modules={[Navigation, Pagination]}
								className="mySwiper blog_slider"
								slidesPerView={1}
								pagination={{
									clickable: true,
								}}
							>								{

									blogDetails && (blogDetails.img).map((item, i) => (
										<SwiperSlide className="" key={i}>
											<Div className="cs-post_thumb cs-radius_15">
												<img src={process.env.REACT_APP_BASE_URL + item} alt="Post" className="w-100 cs-radius_15" />
											</Div>
										</SwiperSlide>
									))
								}
							</Swiper>
							<Div className="cs-post_info post_info">
								<Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
									<span className="cs-posted_by">{moment(blogDetails && blogDetails.date).format('DD-MMM-YYYY')}</span>
									<Link to="/blog" className="cs-post_avatar">{blogDetails && blogDetails.category}</Link>
								</Div>
								<h2 className="cs-post_title">{blogDetails && blogDetails.title}</h2>
								<p className="" dangerouslySetInnerHTML={{ __html: blogDetails && blogDetails.Desc }}></p>
								{blogDetails && blogDetails.quote !== '' &&
									<blockquote className="cs-primary_font ">{blogDetails && blogDetails.quote}
									</blockquote>}
							</Div>
						</Div>
						{/* End Details Post Content */}

						{/* Start Comment Section */}
						<Spacing lg='30' md='30' />
						<h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
						<Spacing lg='5' md='5' />
						<p className="cs-m0">Your email address will not be published. Required fields are marked *</p>
						<Spacing lg='40' md='30' />
						<form className="row" onSubmit={handleSubmit}>
							<Div className="col-lg-6">
								<label>First Name*</label>
								<input type="text" className="cs-form_field" value={contactdetl.firstname} name='firstname' onChange={handleContactdetail} />
								<Div className="cs-height_20 cs-height_lg_20" />
								<p style={{ color: "red" }}> {errror.firstname}</p>
								<Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
							<Div className="col-lg-6">
								<label>Last Name</label>
								<input type="text" className="cs-form_field" value={contactdetl.lastname} name='lastname' onChange={handleContactdetail} />
								<Div className="cs-height_20 cs-height_lg_20" />
								<Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
							<Div className="col-lg-6">
								<label>Company</label>
								<input type="text" className="cs-form_field" value={contactdetl.company} name='company' onChange={handleContactdetail} />
								<Div className="cs-height_20 cs-height_lg_20" />
								<Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>
							<Div className="col-lg-6">
								<label>Email*</label>
								<input type="text" className="cs-form_field" value={contactdetl.email} name='email' onChange={handleContactdetail} />
								<Div className="cs-height_20 cs-height_lg_20" />
								<p style={{ color: "red" }}> {errror.email}</p>
								<Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>

							<Div className="col-lg-12">
								<label >Phone Number*</label>
								<PhoneInput
									value={contactdetl.phonenumber}
									className="cs-form_field"
									defaultCountry="LB"
									onChange={(e) => { setContactdetl({ ...contactdetl, phonenumber: e }); setError({ ...errror, "phonenumber": '' }) }}
								/>
								<p style={{ color: "red" }}> {errror.phonenumber}</p>
								<Div data-lastpass-icon-root="true" style={{ position: 'relative !important', height: '0px !important', width: '0px !important', float: 'left !important' }} /></Div>

							<Div className="col-lg-12">
								<label>Write Your Comment</label>
								<textarea value={contactdetl.comments} cols={30} rows={7} className="cs-form_field" name='comments' onChange={handleContactdetail} />
								<Div className="cs-height_25 cs-height_lg_25" />
							</Div>
							<Div className="col-lg-12">

								{buttonview == false ?
									<button className="cs-btn cs-style1">
										<span>Send Message</span>
										<Icon icon="bi:arrow-right" />
									</button>
									:
									<button className="cs-btn cs-style1">
										<span>Please Wait...</span>										
									</button>}
							</Div>
						</form>

					</Div>
					<Div className="col-xl-3 col-lg-4 offset-xl-1">
						<Spacing lg='0' md='80' />
						<Sidebar />
					</Div>
				</Div>
			</Div>
			<Spacing lg='80' md='40' />

			<Div className="container">
				<Cta
					title='Let’s disscuse make <br />something <i>cool</i> together'
					btnText='Apply For Meeting'
					btnLink='/contact'
					bgSrc='/images/cta_bg.jpeg'
				/>
			</Div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</>
	)
}
