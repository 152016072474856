import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Loader from '../Loader';

export default function PortfolioPage() {
	pageTitle('Service');
	const location = useLocation();
	


	const [active, setActive] = useState(location && location.state && location.state.categoryId ? location.state.categoryId : 'all');
	const [subcatActive, setSubcatActive] = useState(location && location.state && location.state.subcategoryId ? location.state.subcategoryId : 'all');
	const [itemShow, setItemShow] = useState(9);
	const [loader, setLoader] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		setActive(location && location.state && location.state.categoryId ? location.state.categoryId : 'all')
		setSubcatActive(location && location.state && location.state.subcategoryId ? location.state.subcategoryId : 'all')
	}, [location]);

	useEffect(() => {
		getCategory()
	}, []);

	useEffect(() => {
		getService()
	}, [active, subcatActive]);

	useEffect(() => {
		getSubCategory()
	}, [active, subcatActive]);

	const [services, setServices] = React.useState([])
	const getService = async () => {

		setLoader(true)
		await axios.get(process.env.REACT_APP_BASE_URL + `service/GetServicewithcategoryPublic?category_id=${active}&subcategory_id=${subcatActive} `).then((res) => {			
			setServices(res.data.service)
			setLoader(false)
		}).catch((error) => {
			console.log(error, "error");
			setLoader(false)
		});
	};

	const [categoryList, setCategoryList] = React.useState([])
	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategoryPublic`).then((res) => {		
			setCategoryList(res.data.category)}).catch((error) => {
			console.log(error, "error");
		});
	};

	const [subCategoryList, setSubCategoryList] = React.useState([])
	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Subcategory/GetSubcategorybasedonCategory?category_id=${active}`).then((res) => {
			setSubCategoryList(res.data.subcategory)
		}).catch((error) => {
			console.log(error, "error");
		});
	};

	if (loader === true) {
        return <Loader />
    }

	return (
		<>
			<PageHeading
				title="Services"
				bgSrc="images/portfolio_hero_bg.jpeg"
				pageLinkText="Services"
			/>
			<Spacing lg="80" md="40" />
			<Div className="container service_main">
				{categoryList && categoryList.length > 0 &&
					<Div className="cs-portfolio_1_heading">
						<Box>
							<SectionHeading title='' subtitle="Our Services" />
							<h3 className='light_mode_text'>Categories</h3>
						</Box>
						<Div className="cs-filter_menu cs-style1">
							<ul className="cs-mp0 cs-center">
								<li className={active === 'all' ? 'active' : ''}>
									<span onClick={() => { setActive('all'); setSubcatActive('all') }}>All</span>
								</li>
								{categoryList && categoryList.map((item, index) => (
									<li
										className={active === item._id ? 'active' : ''}
										key={index}
									>
										<span onClick={() => { setActive(item._id); setSubcatActive('all') }}>
											{item.category_name}
										</span>
									</li>
								))}
							</ul>
						</Div>
					</Div>}
				{active !== 'all' && subCategoryList && subCategoryList.length > 0 &&
					<Div className="cs-portfolio_1_heading">
						<h3></h3>
						<Div className="cs-filter_menu cs-style1">
							<ul className="cs-mp0 cs-center">
								<li className={subcatActive === 'all' ? 'active' : ''}>
									<span onClick={() => setSubcatActive('all')}>All</span>
								</li>
								{subCategoryList && subCategoryList.map((subitem, i) => (
									<li
										className={subcatActive === subitem._id ? 'active' : ''}
										key={i}
									>
										<span onClick={() => setSubcatActive(subitem._id)}>
											{subitem.subcategory_name}
										</span>
									</li>
								))}
							</ul>
						</Div>
					</Div>}
				<Spacing lg="90" md="45" />
				{services && services.length > 0 ? <>
					<Div className="row">
						{/* portfolioData.slice(0, itemShow) */}
						{services && services.slice(0, itemShow).map((item, index) => (
							<Div
								className={`${index === 3 || index === 6 ? 'col-md-6 col-lg-4' : 'col-md-6 col-lg-4'} `}
								key={index}
							>
								<Portfolio
									title={item.service_name}
									subtitle={'See Details'}
									href={'/service/service-details'}
									src={item.image}
									variant="cs-style1 cs-type1"
									serviceIndex={index}
									service={services}
									item={item}
								/>
								<Spacing lg="25" md="25" />
							</Div>
						))}
					</Div>

					<Div className="text-center">
						{services && services.length <= itemShow ? ('') : (
							<>
								<Spacing lg="65" md="20" />
								<span className="cs-text_btn" onClick={() => setItemShow(itemShow + 6)}><span>Load More</span><Icon icon="bi:arrow-right" /></span>
							</>
						)}
					</Div></> : <>
					<h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No Services Found</h2><Spacing lg="90" md="45" />
				</>
				}
			</Div>
			<Spacing lg="100" md="20" />
			
			<Div className="container">
				<Cta
					// title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
					title="Let’s Create <br />something Remarkable!"
					btnText="Book a Meeting Now"
					btnLink="/contact"
					bgSrc="/images/cta_bg.jpeg"
				/>
			</Div>
		</>
	);
}
