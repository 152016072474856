import React from 'react';
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionData360Campaign, accordionDataContentCreation } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";

const Campaigns = () => {
  const colordata = useSelector((state) => state && state.colordata)

  return (
 
    <>
          <Helmet>
            <title>360 Digital Marketing Agency | 360 Marketing Services</title>
              <meta
               name="description"
              content="Explore 360 Campaign services at The Impression in Dubai. Deliver impactful, cohesive brand messages across multiple platforms to engage your audience."
              />
        </Helmet>
      <SectionHeading
        mainTitle="360-Campaigns"
        caption="Crafting Impactful 360° Marketing Campaigns"
        para="360° campaigns are designed to immerse your audience in a cohesive brand experience. By combining digital channels, creative content, and engagement strategies, we deliver messaging that resonates deeply and drives measurable conversions. From initial ideation to execution, our campaigns are crafted to maximize ROI and amplify your brand's impact."
      ></SectionHeading>

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle="What We Deliver with 360° Campaigns"
            para='Creating Immersive Brand Experiences
            Our 360° campaigns go beyond traditional marketing, offering:'
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Strategy Development:</h4>{" "}
                  Designing campaigns tailored to your brand goals and audience preferences.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Multi-Channel Integration:</h4> Capturing
                  Leveraging platforms like social media, Google Ads, and content marketing to create seamless messaging.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Engagement-Focused Content:</h4>{" "}
                  Producing visually compelling and interactive content that keeps your audience engaged.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Performance Tracking:</h4>Writing
                  Using advanced analytics to refine strategies and ensure optimal results.
                </li>
               
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />


      

      <Div className="row">
        <SectionHeading
          caption="Why Choose The Impression for 360° Campaigns?"
        >
        
          <Box>
            <ul>
              <li>
                <h4>Comprehensive Strategy:</h4> A
                We design campaigns tailored to your business goals, ensuring every channel works in harmony.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4>Expert Integration:</h4>  From SEO optimization to PPC advertising, our multi-channel approach guarantees consistent messaging.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4> Enhanced Engagement:</h4> Create memorable experiences that captivate your audience and drive action.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4>Data-Driven Decisions:</h4> Leverage analytics to refine campaigns and maximize ROI.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4>Localized Expertise:</h4> With deep knowledge of the Dubai market, we create strategies that resonate with local audiences.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="100" md="80" />

      {/* Testimonials */}
      
      {/* <Div className="container">
        <SectionHeading subtitle="Frequently Asked Questions About Content Creation Services" />
        <Div 
        className="row"
        >
          <Div 
            className={
              accordionData360Campaign.length > 7 ? "col-xl-5 col-lg-6" : ""
            }
          >
            <Accordion name="360Campaign" />
          </Div>

          {accordionData360Campaign.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="360Campaign" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>
 
  )
}

export default Campaigns