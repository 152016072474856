import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";
import { useParams, useLocation, Link, Navigate, useNavigate } from 'react-router-dom'
import FaqforCC from "../FaqforCC";
import { accordionDataProgrammaticAdvertising } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";


const ProgrammaticAdvertising = () => {
  const navigate = useNavigate()

  return (
    <>
          <Helmet>
                  <title>Best Programmatic Advertising Agency | The Impression</title>
                  <meta
                   name="description"
                  content="Unlock targeted advertising with The Impression's programmatic advertising services in Dubai. Automate, optimize, and reach your audience effectively."
                     />
         </Helmet>
      <SectionHeading
        mainTitle="Programmatic Advertising"
        caption="Revolutionizing Digital Advertising"
        subtitle="Precision and Efficiency in Advertising"
        para="Programmatic advertising automates the buying and placement of ads, leveraging real-time bidding to ensure your message reaches the right audience at the perfect moment. This cutting-edge approach maximizes ROI and optimizes ad performance."
      ></SectionHeading>

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle=" How Programmatic Advertising Works"
            para="Automated Ad Placement for Maximum Impact"
          >
            <Box>
              <ul>
                <li>
                  <h4>Real-Time Bidding:</h4> Dynamic auctions determine the
                  best ad placements in milliseconds.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Targeted Reach:</h4> Advanced algorithms pinpoint the most
                  relevant audience segments.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Multi-Channel Integration:</h4> Seamless delivery of ads
                  across websites, mobile apps, and social media.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Performance Optimization:</h4> Continuous refinement of
                  campaigns based on real-time analytics.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle="Industries We Serve"
            para="Tailored Programmatic Advertising for Every Sector"
          ></SectionHeading>
        </Div>
        <Box>
          <ul>
            <li>
              <h4 style={{ display: "inline" }}>Real Estate:</h4> Targeted campaigns for luxury property
              promotion and <Link
                to="/service/lead-generation-and-acquisition"
                state={{ index: 4 }} // Pass state directly through the `state` prop
                style={{ color: "rgb(238 45 249)", textDecoration: "underline", cursor: "pointer" }}
              >
                lead generation
              </Link>.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Healthcare:</h4> Efficient reach for healthcare promotion
              campaigns and medical services.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>E-commerce:</h4> Drive sales with PPC for e-commerce websites
              and <Link
                to="/service/social-media-advertising"
                state={{ index: 2 }} // Pass state directly through the `state` prop
                style={{ color: "rgb(238 45 249)", textDecoration: "underline", cursor: "pointer" }}
              >
                social media advertising
              </Link>
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Hospitality:</h4> Attract guests through tourism promotion
              marketing and hotel digital lead generation.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>F&B (Food & Beverage):</h4> Boost visibility with restaurant
              digital marketing and food delivery service campaigns.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Insurance:</h4> Enhance brand awareness and lead generation
              with insurance PPC campaigns and client acquisition strategies.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Automotive:</h4> Drive conversions with vehicle promotion ads
              and automotive marketing strategies.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Education:</h4> Capture student interest with education PPC
              advertising and online course marketing.
            </li>
            <Spacing lg="25" md="25" />

            <li>
              <h4 style={{ display: "inline" }}>Technology:</h4> Engage B2B and B2C audiences with SaaS
              customer acquisition ads and tech solutions advertising.
            </li>
          </ul>
        </Box>
      </Div>
      <Spacing lg="30" md="20" />

      <Div className="row">
        <SectionHeading
          caption=" Why Choose The Impression for Programmatic Advertising?"
          para="Optimizing Ad Campaigns with Precision"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Advanced Technology:</h4> Cutting-edge tools for real-time
                bidding and audience targeting.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4  style={{ display: "inline" }}>Data-Driven Strategies:</h4> Analytics-backed decisions to
                enhance campaign performance.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Expert Team:</h4> Specialists in programmatic solutions for
                diverse industries.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Custom Solutions:</h4> Tailored campaigns that align with
                your business goals and market needs.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Proven Results:</h4> A track record of successful campaigns
                with measurable outcomes.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="100" md="80" />

      {/* Testimonials */}

      {/* <Div className="container">
        <SectionHeading
          subtitle="FAQs About Programmatic Advertising"
          para="Your Programmatic Advertising Questions Answered"
        />
        <Div className="row">
          <Div
            className={
              accordionDataProgrammaticAdvertising.length > 7
                ? "col-xl-5 col-lg-6"
                : ""
            }
          >
            <Accordion name="ProgrammaticAdvertising" />
          </Div>
          {accordionDataProgrammaticAdvertising.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="ProgrammaticAdvertising" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>
  );
};

export default ProgrammaticAdvertising;
