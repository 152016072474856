import React from 'react'
import { useState } from 'react'
import Div from '../Div'
import { Link, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { accordionDataContentCreation, accordionDataDigitalMedia, accordionDataSocialMediaManagement } from './AccordionData';
// import { accordionDataSocialMediaManagement, accordionDataDigitalMedia, accordionDataContentCreation } from './accordionData';


export default function More() {
    const [selected, setSelected] = useState(0)
    const params = useParams()
    console.log(params)
    const handelToggle = (index) => {
        if (selected === index) {
            return setSelected(null)
        }
        setSelected(index)
        
    }

    // const accordionDataSocialMediaManagement = [
    //     {
    //         question: 'Why Should I Hire a Social Media Management Agency?',
    //         answer: 'Agencies like The Impression save you time and deliver results with strategies like insurance client acquisition and real estate promotion services.'
    //     },
    //     {
    //         question: 'How Long Does It Take to See Results?',
    //         answer: 'Results vary, but campaigns like PPC for e-commerce websites or SEO for restaurants typically show impact within weeks.'
    //     },
    //     {
    //         question: 'Do Social Media Agencies Help with Paid Advertising?',
    //         answer: 'Yes, we run targeted campaigns like Google Ads for medical services and insurance PPC campaigns to boost visibility.'
    //     },
    //     {
    //         question: 'How Much Does Social Media Management Cost?',
    //         answer: 'Costs depend on scope, including services like restaurant branding strategy or hospitality digital lead generation. Contact us for details.'
    //     }
    // ];
    // const accordionDataDigitalMedia = [
    //     {
    //         question: 'What is a digital strategy, and why is it important for my business?',
    //         answer: 'It’s a plan to use digital channels effectively. We specialize in strategies for industries like real estate and e-commerce to achieve results.'
    //     },
    //     {
    //         question: 'What is media buying, and how does it work?',
    //         answer: 'It’s purchasing ad space on platforms like Google and TikTok. We manage campaigns for PPC advertising and insurance Google Ads services.'
    //     },
    //     {
    //         question: 'Which platforms do you specialize in for media buying?',
    //         answer: 'We excel in Google, Meta, TikTok, and LinkedIn, with expertise in Google Ads for medical services and PPC for e-commerce websites.'
    //     },
    //     {
    //         question: 'Can you help with both B2B and B2C campaigns?',
    //         answer: 'Yes, we handle B2B SaaS ads and B2C hospitality campaigns effectively.'
    //     },
    //     {
    //         question: 'Do you manage budgets for media buying campaigns?',
    //         answer: 'Yes, we optimize budgets for e-commerce growth campaigns and restaurant branding strategies to maximize ROI.'
    //     },
    //     {
    //         question: 'Do you provide support after campaign launch?',
    //         answer: 'Yes, we offer post-launch support, including performance tracking and real-time optimization.'
    //     },
    //     {
    //         question: 'Can you help with audience targeting for campaigns?',
    //         answer: 'Yes, we specialize in audience segmentation for real estate lead generation and F&B digital marketing services.'
    //     },
    //     {
    //         question: 'How do you track the success of campaigns?',
    //         answer: 'We use advanced tools to monitor metrics like ROI and conversions for insurance PPC campaigns and e-commerce advertising campaigns.'
    //     },
    //     {
    //         question: 'Do you work with small businesses?',
    //         answer: 'Absolutely! We create tailored strategies for startups and SMBs in industries like education PPC advertising and restaurant customer acquisition.'
    //     },
    //     {
    //         question: 'How quickly can you launch a campaign?',
    //         answer: 'We can launch campaigns promptly, including Google Ads for real estate developers and hospitality digital lead generation, often within days after planning.'
    //     }
    // ];
    

    // const accordionDataContentCreation = [
    //     {
    //         question: 'Why should I hire a content creation agency in Dubai?',
    //         answer: 'We deliver high-quality, targeted content and are trusted by high-end clients in industries like healthcare and real estate.'
    //     },
    //     {
    //         question: 'What industries do you work with?',
    //         answer: 'We work with insurance, hospitality, real estate, F&B, healthcare, education, and more.'
    //     },
    //     {
    //         question: 'What types of content do you create?',
    //         answer: 'We create videos, photography, blogs, ad copies, and social media content.'
    //     },
    //     {
    //         question: 'How long does it take to deliver a project?',
    //         answer: 'Timelines vary depending on project size, contact us for more info.'
    //     },
    //     {
    //         question: 'Do you provide content in multiple languages?',
    //         answer: 'Yes, we offer content in English, Arabic, and other languages.'
    //     },
    //     {
    //         question: 'What is your pricing structure?',
    //         answer: 'Our pricing is customized based on project scope and requirements. Contact us for details.'
    //     },
    //     {
    //         question: 'Can you help improve my website’s SEO rankings?',
    //         answer: 'Yes, we specialize in SEO for insurance websites, e-commerce platforms, and other industries to boost search engine visibility.'
    //     },
    //     {
    //         question: 'Do you offer social media management services?',
    //         answer: 'Yes, we manage platforms like Instagram, LinkedIn, and TikTok, creating engaging content tailored for F&B and hospitality industries.'
    //     },
    //     {
    //         question: 'Can you handle large-scale projects?',
    //         answer: 'Absolutely, we have experience managing large campaigns like real estate lead generation and healthcare brand awareness ads.'
    //     },
    //     {
    //         question: 'Do you offer revisions to the content?',
    //         answer: 'Yes, we provide revisions to ensure the content aligns with your vision and goals.'
    //     },
    //     {
    //         question: 'Do you create content for specific campaigns like PPC or SEO?',
    //         answer: 'Yes, we specialize in content for PPC advertising for insurance agencies, Google Ads for real estate, and SEO for online stores.'
    //     },
    //     {
    //         question: 'Can you help with branding and design?',
    //         answer: 'Yes, we offer branding, infographics, and marketing materials tailored for industries like F&B and education.'
    //     },
    //     {
    //         question: 'Do you provide analytics or reporting for campaigns?',
    //         answer: 'Yes, we deliver detailed insights to measure the performance of e-commerce advertising campaigns and hospitality growth strategies.'
    //     },
    //     {
    //         question: 'Do you collaborate with in-house teams?',
    //         answer: 'Absolutely, we seamlessly work with your team to align our strategies with your goals for digital ads for tourism or restaurant customer acquisition.'
    //     },
    //     {
    //         question: 'What is your process for creating content?',
    //         answer: 'Our process involves research, strategy, creation, and optimization to ensure high-quality results for real estate promotion services and other industries.'
    //     }
    // ];




    return (

        <>
        {params.name === 'content-creation' && 
        <Div className="cs-accordians cs-style1">
            {accordionDataContentCreation.map((item, index) => (
                <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
                    <Div className="cs-accordian_head" onClick={() => handelToggle(index)}>
                        <h2 className="cs-accordian_title">{item.question}</h2>
                        
                        <span className="cs-accordian_toggle cs-accent_color">
                            <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                            </svg>
                        </span>
                    </Div>
                    <Div className='cs-accordian_body'>
                        <Div className="cs-accordian_body_in">{item.answer}</Div>
                    </Div>
                </Div>
            ))}
        </Div>}

        

        {params.name === 'DigitalMedia' && 
        <Div className="cs-accordians cs-style1">
            {accordionDataDigitalMedia.map((item, index) => (
                <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
                    <Div className="cs-accordian_head" onClick={() => handelToggle(index)}>
                        <h2 className="cs-accordian_title">{item.question}</h2>
                        
                        <span className="cs-accordian_toggle cs-accent_color">
                            <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                            </svg>
                        </span>
                    </Div>
                    <Div className='cs-accordian_body'>
                        <Div className="cs-accordian_body_in">{item.answer}</Div>
                    </Div>
                </Div>
            ))}
        </Div>}


        {params.name === 'SocialMediaManagement' && 
        <Div className="cs-accordians cs-style1">
            {accordionDataSocialMediaManagement.map((item, index) => (
                <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
                    <Div className="cs-accordian_head" onClick={() => handelToggle(index)}>
                        <h2 className="cs-accordian_title">{item.question}</h2>
                        
                        <span className="cs-accordian_toggle cs-accent_color">
                            <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                            </svg>
                        </span>
                    </Div>
                    <Div className='cs-accordian_body'>
                        <Div className="cs-accordian_body_in">{item.answer}</Div>
                    </Div>
                </Div>
            ))}
        </Div>}
        </>
    )
}
