import React, { useEffect,useState } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeadingService from '../PageHeading/PageHeadingService'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Accordion from '../Accordion'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Icon } from '@iconify/react'
import moment from 'moment';
import { Box, Button, Grid } from '@mui/material'
import VideoModal from '../VideoModal'
import Slider from 'react-slick'
import { useSelector } from "react-redux"
import axios from 'axios'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import LeadforgeContact from './Leadforgecontact';
import LogoList from '../LogoList'
import TrustedList from '../TrustedList'
import imgdemo from './../../images/Leadforge_Banner.jpg'
import LeadforgeContent from './D2BServices/LeadforgeContent'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));


export default function PortfolioDetailsPageLeadForge() {
let bannerimage = '/images/Leadforge Banner.jpg'
  const location = useLocation();
  const params = useParams()
  


  pageTitle('Portfolio Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [services, setServices] = useState([]);
const [indexPos, setIndexPos] = React.useState(location?.state?.index || 0);
const [individualService, setIndividualService] = React.useState(null);
  // const [indexPos, setIndexPos] = React.useState(location && location ? location.state.index : 0)


  const [open, setOpen] = React.useState(false);
  const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [btndisable, setBtndisable] = useState(false)
	const colordata = useSelector((state) => state && state.colordata)

  // Effect to handle location.state.index
useEffect(() => {
  if (location?.state?.index !== undefined) {
    setIndexPos(location.state.index);
  }
}, [location]); // Runs whenever the location changes

// Effect to update 'individualService' when services or indexPos changes
useEffect(() => {
  if (services.length > 0 && indexPos >= 0 && indexPos < services.length) {
    setIndividualService(services[indexPos]);
  }
}, [services, indexPos]); // Runs whenever either services or indexPos changes

// Effect to fetch services (this should run only when index or params change)
useEffect(() => {
  if (params.serviceDetailsId === 'service-details') {
    getcategorywithSerice(); // Fetch services when index or params change
  }
  else if (params.serviceDetailsId === 'd2g'){
    getcategorywithSerice(); // Fetch services when index or params change
  }
  else {
    getcategorywithSerice(); // Fetch services when index or params change
  }
}, [params, location]); // This effect runs when `params` or `location` changes



const getcategorywithSerice = async () => {
    try {
 
        const response = await axios.get(process.env.REACT_APP_BASE_URL + 'category/CategoryWithServices');
        const data = response.data;
        if(params.serviceDetailsId === 'service-details'){
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2C")
        // console.log(filterData[0].services)
        setServices(filterData[0].services)
        }
        if(params.serviceDetailsId === 'd2g'){
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2G")
        // console.log(filterData[0].services)
        setServices(filterData[0].services)
        }else{
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2B")
        // console.log(filterData[0].services)
        setServices(filterData[0].services)
        }
      			
    } catch (error) {
        console.log(error, 'error');
    }
}

//   const [services, setServices] = React.useState(location && location ? location.state.data : [])
//   const [indexPos, setIndexPos] = React.useState(location && location ? location.state.index : 0)
//   const [individualService, setIndividualService] = React.useState(services[indexPos])

//   useEffect(() => {
//     setIndexPos(location && location ? location.state.index : 0)
//     setServices(location && location ? location.state.data : [])
//   }, [location])

//   useEffect(() => {
//     setIndividualService(services[indexPos])
//   }, [location, services, indexPos])


  const handleChangeService = (e, val) => {
    e.preventDefault()
    if (val === 'previous') {
      if (indexPos > 0) {
        const index = indexPos - 1;
        setIndexPos(index)
        setIndividualService(services[index])
      }
    }
    else if (val === 'next') {
      if (indexPos < (services.length - 1)) {
        const index = indexPos + 1;
        setIndexPos(index)
        setIndividualService(services[index])
      }
    }
  } 
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

 

	const [serviceavlb, setServiceavlb] = useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	})

  const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
	}


	const handleClickOpen = () => {
		setOpen(true);
		var element = document.getElementById("html_main");
		element.classList.add("modalOpen");
	};

	const handleClose = () => {
		setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })


		setServiceavlb({
			branding: false,
			campaigns_360: false,
			content_creation: false,
			digital_strategy_media_buying: false,
			social_media_management: false,
			research_development: false,
			data_mining: false,
			lead_generation: false,
			websites_applications: false,
			advisory_consulting_services: false,
		});
		setOpen(false);
		var element = document.getElementById("html_main");
		element.classList.remove("modalOpen");
	};

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

//To download Leadforge PDF:
	const handledownloadOpen = () => {
		const link = document.createElement('a');
		link.href = '/images/LeadForge.pdf'; 
		link.download = 'Leadforge_brochure.pdf';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	  };

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		else if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		else if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		else if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		else if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setBtndisable(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
				setBtndisable(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setOpen(false)
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setBtndisable(false)
				console.log(err)
			})
		}
	}

	
  return (
    <Box className="portfolioDetail">
      {individualService && individualService.service_name == "LeadForge" ? (
        <img src={imgdemo}></img>
      ) : (
        <Slider {...settings} className="cs-gap-12 cs-arrow_style4">
          {individualService &&
            individualService.additionalimages?.length > 0 &&
            individualService.additionalimages.map((item, i) => (
              <PageHeadingService
                title="Service Details"
                //  bgSrc='/images/Leadforge Banner.jpg'
                bgSrc={item}
                pageLinkText={params.serviceDetailsId}
              />
            ))}
        </Slider>
      )}

      <Spacing lg="50" md="40" />
      {/* <Spacing lg='130' md='40' /> */}
      <Div className="container">
        {/* {
          individualService && individualService.service_name.includes("LeadForge") ? <VideoModal
            videoSrc="/images/video/LeadForgeyoutubesize.mp4"
            bgUrl="/images/video_bg.jpeg"
          /> : 
          <Swiper
            navigation={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {individualService && individualService.additionalimages.map((src, i) => (
              <SwiperSlide key={i}><img src={process.env.REACT_APP_BASE_URL + src} alt="Details" className="cs-radius_15 w-100" /></SwiperSlide>))}
          </Swiper>
        } */}

        <Div className="cs-page_navigation cs-center">
          {services && services.length > 1 && (
            <>
              <Div>
                <Link
                  className={`cs-text_btn cs-type1 ${
                    indexPos === 0 ? "disabled" : ""
                  }`}
                  onClick={(e) => handleChangeService(e, "previous")}
                  disabled={indexPos === 0}
                >
                  <span> Prev Service</span>
                  <Icon icon="bi:arrow-right" />{" "}
                </Link>
              </Div>

              <Div>
                <Link
                  className={`cs-text_btn ${
                    indexPos === services.length - 1 ? "disabled" : ""
                  }`}
                  onClick={(e) => handleChangeService(e, "next")}
                  disabled={indexPos === services.length - 1}
                >
                  <span> Next Service</span>
                  <Icon icon="bi:arrow-right" />{" "}
                </Link>
              </Div>
            </>
          )}
        </Div>
        {/* <Spacing lg='65' md='10' /> */}
        {individualService &&
          individualService.service_name.includes("LeadForge") && (
            <VideoModal
              videoSrc="/images/video/LeadForgeyoutubesize.mp4"
              bgUrl="/images/Home-page-lead-forge-thumbnail.jpg"
            />
          )}

        <Spacing lg="50" md="40" />
        <Div className="row">
          <Div className="col-lg-12 ">
            <SectionHeading
              mainTitle={individualService && individualService.service_name}
              subtitle={individualService && individualService.category_name}
            >
              <Grid container columnSpacing={4} rowSpacing={4} className="mt-3">
                <Grid item xs={12} sm={8} md={8}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        individualService && individualService.description,
                    }}
                  ></p>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className="text-center">
                  <a
                    href="https://www.activecampaign.com/?_r=SSFGRVE7"
                    target="_blank"
                  >
                    <img src="/images/activecampaign.png" alt="meirc" />
                  </a>
                </Grid>
              </Grid>
            </SectionHeading>
          </Div>
          <Spacing lg="50" md="20" />

          <Div className="col-lg-12">
            <Div className="row ">
              <LeadforgeContent />
            </Div>
          </Div>

          {individualService &&
            individualService.service_name == "LeadForge" && (
              <Div className="col-lg-5">
                <Spacing lg="60" md="40" />
                <h2 style={{ marginRight: 30 }} className="light_pink">
                  Trusted By
                </h2>
                <img
                  src="/images/Meirc.jpg"
                  alt="meirc"
                  className="trustedby_img"
                />

                {/* <Div className="container">
				<TrustedList />
			</Div> */}
              </Div>
            )}

          <LeadforgeContact />

          {/* <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40' />
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Service Info -</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
             
              {individualService && individualService.additionalinfo && individualService.additionalinfo.map((item, i) => (
                item.key !== '' && <Div className="col-6" key={i}>
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>{item.key}:</h3>
                  <p className='cs-m0'>{item.answer}</p>
                  <Spacing lg='30' md='30' />
                </Div>
              ))}
            </Div>
          </Div> */}
        </Div>
        <Div className="text-center mt-4">
          <Button onClick={handledownloadOpen} className="theme_btn_new">
            Download Brochure
          </Button>
        </Div>
        {/* <Div className="text-center mt-4">
          <Button onClick={handleClickOpen}  className="theme_btn_new">Download Our Portfolio</Button>
        </Div> */}
      </Div>

      <Spacing lg="20" md="20" />

      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1 FAQ_section">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div> */}

      {/* <Spacing lg='100' md='80' /> */}
      {/* <Cta
        title='ramy@theimpression.me'
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      /> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="custom_modal"
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          className="modal_title"
          id="customized-dialog-title"
        >
          <div> Get a Quote </div>{" "}
          <div className="ml-auto">
            <Icon icon="mdi:close" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form className="row">
            <Div className="col-sm-6">
              <label className="cs-primary_color">First Name*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.firstname}
                name="firstname"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.firstname}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Last Name </label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.lastname}
                name="lastname"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Company</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.company}
                name="company"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Email*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.email}
                name="email"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.email}</p>
              <Spacing lg="20" md="20" />
            </Div>

            <Div className="col-sm-6">
              <label className="cs-primary_color">Phone Number*</label>
              <PhoneInput
                value={contactdetl.phonenumber}
                className="cs-form_field"
                defaultCountry="LB"
                onChange={(e) => {
                  setContactdetl({ ...contactdetl, phonenumber: e });
                  setError({ ...errror, phonenumber: "" });
                }}
              />
              <p style={{ color: "red" }}> {errror.phonenumber}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <h5 className={colordata == "dark" ? "" : "contacttect"}>
              What services are you interested in?
            </h5>

            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="branding"
                    checked={serviceavlb.branding}
                    onChange={handleServicedetail}
                  />
                }
                label="Branding"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="campaigns_360"
                    checked={serviceavlb.campaigns_360}
                    onChange={handleServicedetail}
                  />
                }
                label="360 Campaigns"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="content_creation"
                    checked={serviceavlb.content_creation}
                    onChange={handleServicedetail}
                  />
                }
                label="Content Creation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="digital_strategy_media_buying"
                    checked={serviceavlb.digital_strategy_media_buying}
                    onChange={handleServicedetail}
                  />
                }
                label="Digital Strategy & Media Buying"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="social_media_management"
                    checked={serviceavlb.social_media_management}
                    onChange={handleServicedetail}
                  />
                }
                label="Social Media Management"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="research_development"
                    checked={serviceavlb.research_development}
                    onChange={handleServicedetail}
                  />
                }
                label="Research & Development"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="data_mining"
                    checked={serviceavlb.data_mining}
                    onChange={handleServicedetail}
                  />
                }
                label="Data Mining"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="lead_generation"
                    checked={serviceavlb.lead_generation}
                    onChange={handleServicedetail}
                  />
                }
                label="Lead Generation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="websites_applications"
                    checked={serviceavlb.websites_applications}
                    onChange={handleServicedetail}
                  />
                }
                label="BrandWebsites & Applicationsing"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="advisory_consulting_services"
                    checked={serviceavlb.advisory_consulting_services}
                    onChange={handleServicedetail}
                  />
                }
                label="Advisory & Consulting Services"
              />
            </Div>
          </form>
        </DialogContent>
        <DialogActions>
          {btndisable ? (
            <button className="cs-btn cs-style1">
              <span>Please wait...</span>
            </button>
          ) : (
            <button className="cs-btn cs-style1" onClick={handleSubmit}>
              <span>Send Message</span>
              <Icon icon="bi:arrow-right" />
            </button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

