import React, { useEffect,useState } from 'react'
import axios from 'axios'
import { useParams, useLocation, Link } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Accordion from '../Accordion'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Icon } from '@iconify/react'
import moment from 'moment';
import { Box, Button } from '@mui/material'
import { useSelector } from "react-redux"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Helmet } from "react-helmet";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export default function ProjectDetailsPage() {
  const location = useLocation();
  const params = useParams()
  pageTitle('Project Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [services, setServices] = React.useState(location && location ? location.state?.data : [])
  const [indexPos, setIndexPos] = React.useState(location && location ? location.state?.index : 0)
  const [individualService, setIndividualService] = React.useState(services[indexPos])


  const handleChangeService = (e, val) => {
    e.preventDefault()
    if (val === 'previous') {
      if (indexPos > 0) {
        const index = indexPos - 1;
        setIndexPos(index)
        setIndividualService(services[index])
      }
    }
    else if (val === 'next') {
      if (indexPos < (services.length - 1)) {
        const index = indexPos + 1;
        setIndexPos(index)
        setIndividualService(services[index])
      }
    }
  }
	const [open, setOpen] = React.useState(false);
  const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [btndisable, setBtndisable] = useState(false)
	const colordata = useSelector((state) => state && state.colordata)

	const [serviceavlb, setServiceavlb] = useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	})
	pageTitle('Home');

  const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
	}


	const handleClickOpen = () => {
		setOpen(true);
		var element = document.getElementById("html_main");
		element.classList.add("modalOpen");
	};

	const handleClose = () => {
		setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })


		setServiceavlb({
			branding: false,
			campaigns_360: false,
			content_creation: false,
			digital_strategy_media_buying: false,
			social_media_management: false,
			research_development: false,
			data_mining: false,
			lead_generation: false,
			websites_applications: false,
			advisory_consulting_services: false,
		});
		setOpen(false);
		var element = document.getElementById("html_main");
		element.classList.remove("modalOpen");
	};

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		else if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		else if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		else if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		else if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setBtndisable(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
				setBtndisable(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setOpen(false)
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setBtndisable(false)
				console.log(err)
			})
		}
	}

  return (
    <Box className='portfolioDetail'>
      {/* <PageHeading
        title='Work Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />*/}
	   <Helmet>
			<meta name="robots" content="noindex, nofollow" />
		</Helmet>
      <Spacing lg='120' md='40' />

      <Div className="container">
        <Swiper
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
        >
          {individualService && individualService.additionalimages?.length > 0 && individualService.additionalimages.map((src, i) => (
            <SwiperSlide key={i}><img src={process.env.REACT_APP_BASE_URL + src} alt="Details" className="cs-radius_15 w-100" /></SwiperSlide>))}
        </Swiper>
        <Spacing lg='60' md='10' />
        <Div className="cs-page_navigation cs-center">
          <Div>

            <Link className={`cs-text_btn cs-type1 ${indexPos === 0 ? 'disabled' : ''}`} onClick={(e) => handleChangeService(e, 'previous')}><span> Prev Work</span><Icon icon="bi:arrow-right" />  </Link>
          </Div>
          <Div>
            <Link className={`cs-text_btn ${indexPos === services.length - 1 ? 'disabled' : ''}`} onClick={(e) => handleChangeService(e, 'next')}><span> Next Work</span><Icon icon="bi:arrow-right" />  </Link>
          </Div>
        </Div>
        <Spacing lg='45' md='10' />
        <Div className="row">
          <Div className="col-lg-12">
            <SectionHeading
              title={individualService && individualService.service_name}
            >
              <Spacing lg='40' md='20' />
              <p dangerouslySetInnerHTML={{ __html: individualService && individualService.description }}></p>
            </SectionHeading>
          </Div>
          {/* <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='10' />
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'> Work Info -</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">

              <Div className="col-6" >
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Created Date:</h3>
                <p className='cs-m0'>{individualService && moment(individualService.createdAt).format("MM-DD-YYYY")}</p>
                <Spacing lg='30' md='30' />
              </Div>

              {individualService && individualService.additionalinfo && individualService.additionalinfo.map((item, i) => (
                item.key !== '' &&
                <Div className="col-6" key={i}>
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>{item.key}:</h3>
                  <p className='cs-m0'>{item.answer}</p>
                  <Spacing lg='30' md='30' />
                </Div>
              ))}
            </Div>
          </Div> */}
        </Div>
        <Div className="text-center mt-4">
          <Button onClick={handleClickOpen} className="theme_btn_new">Download Our Portfolio</Button>
        </Div>

      </Div>
      <BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				className='custom_modal'
				maxWidth='md'
			>
				<DialogTitle sx={{ m: 0, p: 2 }} className='modal_title' id="customized-dialog-title">
					<div> Get a Quote  </div> <div className='ml-auto'>
						<Icon icon="mdi:close" onClick={handleClose} />
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<form className="row">
						<Div className="col-sm-6">
							<label className="cs-primary_color">First Name*</label>
							<input type="text" className="cs-form_field" value={contactdetl.firstname} name='firstname' onChange={handleContactdetail} />
							<p style={{ color: "red" }}> {errror.firstname}</p>
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color">Last Name </label>
							<input type="text" className="cs-form_field" value={contactdetl.lastname} name='lastname' onChange={handleContactdetail} />
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color" >Company</label>
							<input type="text" className="cs-form_field" value={contactdetl.company} name='company' onChange={handleContactdetail} />
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color"  >Email*</label>
							<input type="text" className="cs-form_field" value={contactdetl.email} name='email' onChange={handleContactdetail} />
							<p style={{ color: "red" }}> {errror.email}</p>
							<Spacing lg="20" md="20" />
						</Div>

						<Div className="col-sm-6">
							<label className="cs-primary_color">Phone Number*</label>
							<PhoneInput
								value={contactdetl.phonenumber}
								className="cs-form_field"
								defaultCountry="LB"
								onChange={(e) => { setContactdetl({ ...contactdetl, phonenumber: e }); setError({ ...errror, "phonenumber": '' }) }}
							/>
							<p style={{ color: "red" }}> {errror.phonenumber}</p>
							<Spacing lg="20" md="20" />
						</Div>
						<h5 className={colordata == 'dark' ? "" : "contacttect"}>What services are you interested in?</h5>

						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='branding' checked={serviceavlb.branding} onChange={handleServicedetail} />} label="Branding" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='campaigns_360' checked={serviceavlb.campaigns_360} onChange={handleServicedetail} />} label="360 Campaigns" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='content_creation' checked={serviceavlb.content_creation} onChange={handleServicedetail} />} label="Content Creation" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='digital_strategy_media_buying' checked={serviceavlb.digital_strategy_media_buying} onChange={handleServicedetail} />} label="Digital Strategy & Media Buying" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='social_media_management' checked={serviceavlb.social_media_management} onChange={handleServicedetail} />} label="Social Media Management" />
						</Div>
						<Div className="col-sm-6">

							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='research_development' checked={serviceavlb.research_development} onChange={handleServicedetail} />} label="Research & Development" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='data_mining' checked={serviceavlb.data_mining} onChange={handleServicedetail} />} label="Data Mining" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='lead_generation' checked={serviceavlb.lead_generation} onChange={handleServicedetail} />} label="Lead Generation" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='websites_applications' checked={serviceavlb.websites_applications} onChange={handleServicedetail} />} label="BrandWebsites & Applicationsing" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='advisory_consulting_services' checked={serviceavlb.advisory_consulting_services} onChange={handleServicedetail} />} label="Advisory & Consulting Services" />
						</Div>

					</form>
				</DialogContent>
				<DialogActions>
					{btndisable ? <button className="cs-btn cs-style1" >
						<span>Please wait...</span>
					</button> :
						<button className="cs-btn cs-style1" onClick={handleSubmit}>
							<span>Send Message</span>
							<Icon icon="bi:arrow-right" />
						</button>}
				</DialogActions>
			</BootstrapDialog>
      {/* <Spacing lg='80' md='10' /> */}
      {/* <Cta
        title='ramy@theimpression.me'
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      /> */}
    </Box>
  )
}
