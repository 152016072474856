import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";

import FaqforCC from "../FaqforCC";
import { accordionDataUIUXDesign } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";


const UiUxDesign = () => {
  return (
    <>
            <Helmet>
                <title>UI-UX Design Solutions | The Impression</title>
                <meta
                name="description"
                content="Explore The Impression's UI/UX Design services. Elevate your digital presence with intuitive, user-focused designs that captivate and engage."
                  />
             </Helmet>
      <SectionHeading
        mainTitle="UI/UX Design"
        caption="Designing Seamless Digital Experiences"
        subtitle="UI/UX Solutions for Intuitive Interactions"
        para="
            Our UI/UX design services focus on creating websites and applications that blend aesthetic appeal with seamless functionality. By prioritizing user experience, we help brands deliver impactful digital interactions that inspire engagement and drive conversions."
      ></SectionHeading>
      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle="What We Offer in UI/UX Design"
            para="Crafting Interfaces That Captivate and Perform"
          >
            <Box>
              <ul>
                <li>
                  <h4>User-Centered Design:</h4> Crafting experiences tailored
                  to your audience’s needs and behaviors.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Aesthetic Excellence:</h4> Combining visual appeal with
                  intuitive layouts for impactful interfaces.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Responsive Design:</h4> Ensuring flawless performance
                  across devices and screen sizes.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Interaction Optimization:</h4> Simplifying navigation to
                  enhance user satisfaction and drive actions.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Prototyping & Testing:</h4> Validating designs through
                  user feedback and refining for perfection.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />

      <Div className="row">
        <SectionHeading
          caption="Industries We Serve"
          para="Empowering Businesses with Tailored UI/UX Solutions"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Real Estate:</h4> Designing platforms for property listings,
                virtual tours, and lead generation.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Healthcare:</h4> Streamlining patient journeys with
                intuitive interfaces for clinics and medical services.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>E-commerce:</h4> Creating responsive online stores optimized
                for conversions and user retention.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Hospitality:</h4> Building interactive booking systems and
                captivating experiences for hotels and tourism.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>F&B (Food & Beverage):</h4> Enhancing customer engagement
                with seamless ordering and promotional platforms.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Education:</h4> Simplifying learning portals and student
                recruitment platforms for optimal usability.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Insurance:</h4> Developing user-friendly portals for
                insurance client acquisition and policy management.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Technology:</h4> Designing SaaS interfaces and digital tools
                for tech startups and enterprises.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="50" md="80" />

      <Div className="row">
        <SectionHeading
          caption="Why Choose The Impression for UI/UX Design?"
          para="Why us for your UI/UX Design?"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Audience-Focused:</h4> Creating designs that resonate with
                your target market’s preferences.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Functional Elegance:</h4> Balancing visual impact with
                seamless usability to amplify brand presence.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Cutting-Edge Tools:</h4> Leveraging advanced technologies to
                ensure future-ready designs.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Proven Success:</h4> A portfolio of designs driving
                measurable outcomes across industries.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Collaborative Process:</h4> Partnering with you to align
                design solutions with your goals.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="50" md="80" />

      {/* Testimonials */}

      {/* <Div className="container">
        <SectionHeading
          subtitle="FAQs About UI/UX Design"
          para="Your Questions on UI/UX Design, Answered"
        />

        <Div className="row">
          <Div
            className={
              accordionDataUIUXDesign.length > 7 ? "col-xl-5 col-lg-6" : ""
            }
          >
            <Accordion name="UIUXDesign" />
          </Div>
          {accordionDataUIUXDesign.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="UIUXDesign" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>
  );
};

export default UiUxDesign;
