import React from 'react';
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionDataRDInDigitalMarketing } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";

const ResearchAndDevelopment = () => {
  const colordata = useSelector((state) => state && state.colordata)

    return (
      <>
          <Helmet>
                <title>Research & Development | The Impression</title>
                <meta
                  name="description"
                   content="Explore innovative research and development services at The Impression. Unlock growth with data-driven insights and tailored strategies for your business."
                />
          </Helmet>
        <SectionHeading
          mainTitle="Research & Development"
          caption="Innovating Through Research and Development"
          subtitle="Staying Ahead with Digital R&D Solutions"
          para="Driving innovation through meticulous research and development tailored to the digital consumer market. By uncovering emerging trends, analyzing consumer behaviors, and leveraging technological advancements, we empower brands to stay relevant and competitive."
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              subtitle="What We Deliver with Research and Development"
              para="Empowering Brands with Data-Driven Insights"
            >
              <Box>
                <ul>
                  <li>
                    <h4>Trend Analysis:</h4> Identifying and adapting to the
                    latest digital and consumer trends to position your brand
                    ahead of competitors.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Behavioral Insights:</h4> Understanding consumer
                    behavior to design experiences that resonate and drive
                    engagement.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Technological Integration:</h4> Leveraging advancements
                    like AI and machine learning for cutting-edge solutions.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Custom Solutions:</h4> Tailored strategies aligned with
                    your brand’s goals to ensure maximum impact and relevance.
                  </li>
                </ul>
              </Box>
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="30" md="20" />

        <Div className="row">
          <SectionHeading
            caption="Your Partner in Innovation and Growth"
            para="Why Choose The Impression for R&D?"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Strategic Focus:</h4> We
                  prioritize insights that deliver measurable business outcomes.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Tech-Driven Excellence:</h4>{" "}
                  Advanced tools and technologies inform every decision.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Market Expertise:</h4> Deep
                  understanding of Dubai’s unique market dynamics ensures
                  locally relevant solutions.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>
                    Cross-Industry Applications:
                  </h4>{" "}
                  Proven success in applying R&D insights across industries like
                  e-commerce, healthcare, and hospitality.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Future-Ready Solutions:</h4>{" "}
                  Strategies designed to adapt to the rapidly changing digital
                  landscape.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        {/* Testimonials */}

        {/* <Div className="container">
          <SectionHeading subtitle="Frequently Asked Questions About Digital R&D" />
          <Div className="row">
            <Div
              className={
                accordionDataRDInDigitalMarketing.length > 7 ? "col-xl-5 col-lg-6" : ""
              }
            >
              <Accordion name="RDInDigitalMarketing" />
            </Div>

            {accordionDataRDInDigitalMarketing.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="RDInDigitalMarketing" isSecondColumn={true} />
              </Div>
            )}
          </Div>
        </Div> */}
      </>
    );
}

export default ResearchAndDevelopment