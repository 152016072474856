import React, { useEffect,useState } from 'react'
import { useParams, useLocation, Link, Navigate, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeadingService from '../PageHeading/PageHeadingService'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Accordion from '../Accordion'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Icon } from '@iconify/react'
import moment from 'moment';
import { Box, Button } from '@mui/material'
import VideoModal from '../VideoModal'
import Slider from 'react-slick'
import { useSelector } from "react-redux"
import axios from 'axios'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import LeadforgeContact from './Leadforgecontact';
import LogoList from '../LogoList'
import TrustedList from '../TrustedList'
import imgdemo from './../../images/Leadforge_Banner.jpg'
import ContentCreationPage from './D2CServices/ContentCreationPage'
import DigitalStatergy from './D2CServices/DigitalStatergy'
import SocialMediaManagement from './D2CServices/SocialMediaManagement'
import Campaigns from './D2CServices/Campaigns'
import LeadGeneration from './D2CServices/LeadGeneration'
import ResearchAndDevelopment from './D2CServices/ResearchAndDevelopment'
import CharacterCreation from './D2CServices/CharacterCreation'
import Branding from './D2CServices/Branding'
import ProgrammaticAdvertising from './D2CServices/ProgrammaticAdvertising'
import UiUxDesign from './D2CServices/UiUxDesign'
import DataMining from './D2GServices/DataMining'
import AdvisoryConsulting from './D2GServices/AdvisoryConsulting'
import { Helmet } from "react-helmet";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));


export default function PortfolioDetailsPageX() {
let bannerimage = '/images/Leadforge Banner.jpg'
  const location = useLocation();
  const params = useParams()

  
  
  
const navigate = useNavigate()

  pageTitle('Portfolio Details');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
// -----------------------------------------------------------------------------------------------------------------------------------------------------
const [services, setServices] = useState([]);
const [indexPos, setIndexPos] = React.useState(location?.state?.index || 0);
const [individualService, setIndividualService] = React.useState(null);
  // const [indexPos, setIndexPos] = React.useState(location && location ? location.state.index : 0)


  const [open, setOpen] = React.useState(false);
  const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" });
	const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" });
	const [btndisable, setBtndisable] = useState(false);
	const colordata = useSelector((state) => state && state.colordata);


    const serviceRouteMap = [
      { name : "content-creation" , indexvalue:0 },
      { name : "digital-media-buying-agency" , indexvalue:1 },
      { name : "social-media-management" , indexvalue:2 },
      { name : "360-campaigns" , indexvalue:3 },
      { name : "lead-generation-and-acquisition" , indexvalue:4 },
      { name : "research-and-development" , indexvalue:5 },
      { name : "character-creation-2d-and-3d" , indexvalue:6 },
      { name : "branding" , indexvalue:7 },
      { name : "programmatic-advertising" , indexvalue:8 },
      { name : "ui-ux-design" , indexvalue:9 },
      { name : "data-mining" , indexvalue:0 },
      { name : "advisory-consulting" , indexvalue:1 },
      ]

 
useEffect(() => {
  if (location?.state?.index !== undefined) {
    setIndexPos(location.state.index);
  }else{
    const match = serviceRouteMap.find(
      (service) => service.name === params.serviceDetailsId
    );
    if (match) {
      setIndexPos(match.indexvalue);
    }
  }
}, [location]); // Runs whenever the location changes

// Effect to update 'individualService' when services or indexPos changes
useEffect(() => {
  if (services.length > 0 && indexPos >= 0 && indexPos < services.length) {
    setIndividualService(services[indexPos]);
  }
}, [services, indexPos]); // Runs whenever either services or indexPos changes

// Effect to fetch services (this should run only when index or params change)
useEffect(() => {
  if (params.serviceDetailsId === 'service-details') {

    getcategorywithSerice(); // Fetch services when index or params change

  }
  else if (params.serviceDetailsId === 'd2g'){
    getcategorywithSerice(); // Fetch services when index or params change
   
  }
  else{
    getcategorywithSerice(); // Fetch services when index or params change
    
  }
}, [params, location]); // This effect runs when `params` or `location` changes

const getcategorywithSerice = async () => {
  try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + 'category/CategoryWithServices');
      const data = response.data;

      if(params.serviceDetailsId === 'service-details'){
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2C")

          const updatedServices = filterData.map((service) => {
            console.log(service.services);  // This logs the services inside the current service object
            // Check if service.services is an array and map through it
            const updatedServiceDetails = service.services.map((subService) => {
                // Safely handle the service_name and replace spaces with dashes
                let updatedName = subService?.service_name ? subService.service_name.replace(/\s+/g, "-").toLowerCase() : '';
        
                // Special cases for service_name
                if (subService.service_name === "Content Creation – Photo and videography") {
                    updatedName = "content-creation";
                } else if (subService.service_name === "Digital Strategy and Media Buying") {
                    updatedName = "digital-media-buying-agency";
                } else if (subService.service_name === "Social Media Management") {
                    updatedName = "social-media-management";
                }else if (subService.service_name === "UI/UX Design") {
                  updatedName = "UI-UX-Design";
              }else if (subService.service_name === "Lead Generation and Acquisition") {
                updatedName = "Lead-Generation-and-Acquisition";
            }
        
                // Return the updated subService with modified service_name
                return {
                    ...subService,
                    service_name: updatedName,
                };
            });
        
            // Return the updated service object with modified service_names in the services array
            return {
                ...service,
                services: updatedServiceDetails,  // Replace old services with updated services
            };
        });

          console.log(updatedServices[0].services, "servicesservicesservices")
          setServices(updatedServices[0].services)
          return;
      }


      if(params.serviceDetailsId === 'data-mining' || params.serviceDetailsId === 'advisory-consulting'){
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2G")
          console.log(filterData[0].services,"servicesservicesservices")
          setServices(filterData[0].services)
          return;
      }
      
      if(params.serviceDetailsId === 'leadforge'){
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2B")
          console.log(filterData[0].services,"servicesservicesservices")
          setServices(filterData[0].services)
          return;
      }

      else {
          const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2C");

          const updatedServices = filterData.map((service) => {
            console.log(service.services);  // This logs the services inside the current service object
        
            // Check if service.services is an array and map through it
            const updatedServiceDetails = service.services.map((subService) => {
                // Safely handle the service_name and replace spaces with dashes
                let updatedName = subService?.service_name ? subService.service_name.replace(/\s+/g, "-").toLowerCase() : '';
        
                // Special cases for service_name
                if (subService.service_name === "Content Creation – Photo and videography") {
                    updatedName = "content-creation";
                } else if (subService.service_name === "Digital Strategy and Media Buying") {
                    updatedName = "digital-media-buying-agency";
                } else if (subService.service_name === "Social Media Management") {
                    updatedName = "social-media-management";
                }else if (subService.service_name === "UI/UX Design") {
                  updatedName = "UI-UX-Design";
              }else if (subService.service_name === "Lead Generation and Acquisition") {
                updatedName = "Lead-Generation-and-Acquisition";
            }
        
                // Return the updated subService with modified service_name
                return {
                    ...subService,
                    service_name: updatedName,
                };
            });
        
            // Return the updated service object with modified service_names in the services array
            return {
                ...service,
                services: updatedServiceDetails,  // Replace old services with updated services
            };
        });

          console.log(updatedServices[0].services, "servicesservicesservices")
          setServices(updatedServices[0].services)
          return;
      }

  } catch (error) {
      console.log(error, 'error');
  }
}




// ---------------------------------------------------------------------------------------------------------------------------------------------------------

  // console.log(location.state.data)
  // console.log(location.state.index)
  
//   const [services, setServices] = React.useState(location && location ? location.state.data : [])
  // const [indexPos, setIndexPos] = React.useState(location && location ? location.state.index : 0)
  // const [individualService, setIndividualService] = React.useState(services[indexPos])

  
  const handleChangeService = (e, val) => {
    console.log(val,'service_nameservicesindexservice_name');
    e.preventDefault()
    if (val === 'previous') {
      if (indexPos > 0) {
        const index = indexPos - 1;
        setIndexPos(index)
        setIndividualService(services[index])


        // if (params.serviceDetailsId === "d2g") return;
        if (
          services[index].service_name === "lead-generation-and-acquisition-"
        ) { 
          navigate(`/service/lead-generation-and-acquisition`, {
            state: { index: index, data: services[index] },
          });
          return;
        } if(services[index].service_name === "Data Mining"){
          navigate(`/service/data-mining`, {
            state: { index: index, data: services[index] },
          });
          return;
        }
        else {
          navigate(`/service/${services[index].service_name}`, {
            state: { index: index, data: services[index] },
          });
        }
      }

        
    }
    else if (val === 'next') {
      if (indexPos < (services.length - 1)) {
        const index = indexPos + 1;
        setIndexPos(index)
        setIndividualService(services[index])

        // if(params.serviceDetailsId === 'd2g') return
        if (
          services[index].service_name === "lead-generation-and-acquisition-"
        ) {
          navigate(`/service/lead-generation-and-acquisition`, {
            state: { index: index, data: services[index] },
          });
        }   if(services[index].service_name === "Advisory Consulting"){
          navigate(`/service/advisory-consulting`, {
            state: { index: index, data: services[index] },
          });
          return;
        }else {
          navigate(`/service/${services[index].service_name}`, {
            state: { index: index, data: services[index] },
          });
        }
      }
      
    }
  } 
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };


	const [serviceavlb, setServiceavlb] = useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	})

  const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
	}


	const handleClickOpen = () => {
		setOpen(true);
		var element = document.getElementById("html_main");
		element.classList.add("modalOpen");
	};

	const handleClose = () => {
		setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })


		setServiceavlb({
			branding: false,
			campaigns_360: false,
			content_creation: false,
			digital_strategy_media_buying: false,
			social_media_management: false,
			research_development: false,
			data_mining: false,
			lead_generation: false,
			websites_applications: false,
			advisory_consulting_services: false,
		});
		setOpen(false);
		var element = document.getElementById("html_main");
		element.classList.remove("modalOpen");
	};

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

//To download Leadforge PDF:
	const handledownloadOpen = () => {
		const link = document.createElement('a');
		link.href = '/images/LeadForge.pdf'; 
		link.download = 'Leadforge_brochure.pdf';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	  };

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		else if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		else if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		else if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		else if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setBtndisable(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
				setBtndisable(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setOpen(false)
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setBtndisable(false)
				console.log(err)
			})
		}
	}

  const formatRoute = (serviceName) => {
		return serviceName
		  .replace(/–/g, '-') 
		  .replace(/\s+/g, '-') 
		  .toLowerCase() 
		  .replace(/-$/, '');  
	  };
    const serviceRouteMap1 = {
      "Content Creation – Photo and videography": "content-creation",
      "Digital Strategy and Media Buying": "digital-media-buying-agency",
      "Social Media Management": "social-media-management",
      "360 Campaigns": "360-campaigns",
      "Lead Generation and Acquisition": "lead-generation-and-acquisition",
      "Research and Development": "research-and-development",
      "Character Creation 2D and 3D": "character-creation-2d-and-3d",
      "Branding": "branding",
      "Programmatic Advertising": "programmatic-advertising",
      "UI/UX Design": "ui-ux-design",
    };
    
    const formatServiceRoute = (serviceName) => {
      return serviceRouteMap1[serviceName] || formatRoute(serviceName); // Fallback to `formatRoute` if no mapping found
    };
    const isNoIndexPage = location.pathname === "/service/:d2g" || location.pathname === "/service/service-details";
	
  return (
    <Box className="portfolioDetail">
      {individualService && individualService.service_name == "LeadForge" ? (
        <img src={imgdemo}></img>
      ) : (
        <Slider {...settings} className="cs-gap-12 cs-arrow_style4">
          {individualService &&
            individualService.additionalimages?.length > 0 &&
            individualService.additionalimages.map((item, i) => (
              <PageHeadingService
                title="Service Details"
                //  bgSrc='/images/Leadforge Banner.jpg'
                bgSrc={item}
                pageLinkText={params.serviceDetailsId}
              />
            ))}
        </Slider>
      )}

      <Spacing lg="50" md="40" />

      <Div className="container">
      {isNoIndexPage ? (
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
        ) : (
          <Helmet>
            <meta name="robots" content="index, follow" />
          </Helmet>
        )}
        {/* {
          individualService && individualService.service_name.includes("LeadForge") ? <VideoModal
            videoSrc="/images/video/LeadForgeyoutubesize.mp4"
            bgUrl="/images/video_bg.jpeg"
          /> : 
          <Swiper
            navigation={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {individualService && individualService.additionalimages.map((src, i) => (
              <SwiperSlide key={i}><img src={process.env.REACT_APP_BASE_URL + src} alt="Details" className="cs-radius_15 w-100" /></SwiperSlide>))}
          </Swiper>
        } */}

        <Div className="cs-page_navigation cs-center">
          {services && services.length > 1 && (
            <>
              <Div>
                <Link
                  to={
                    indexPos > 0
                      ? `/service/${formatServiceRoute(services[indexPos - 1].service_name)}`
                      : "#"
                  }
                  className={`cs-text_btn cs-type1 ${indexPos === 0 ? "disabled" : ""
                    }`}
                  onClick={(e) => handleChangeService(e, "previous")}
                  disabled={indexPos === 0}
                >
                  <span> Prev Service</span>
                  <Icon icon="bi:arrow-right" />
                </Link>

              </Div>

              <Div>
                <Link
                  to={
                    indexPos < services.length - 1
                      ? `/service/${formatServiceRoute(services[indexPos + 1].service_name)}`
                      : "#"
                  }
                  className={`cs-text_btn ${indexPos === services.length - 1 ? "disabled" : ""
                    }`}
                  onClick={(e) => handleChangeService(e, "next")}
                  disabled={indexPos === services.length - 1}
                >
                  <span> Next Service</span>
                  <Icon icon="bi:arrow-right" />
                </Link>

              </Div>
            </>
          )}
        </Div>
        {/* <Spacing lg='65' md='10' /> */}
        {individualService &&
          individualService.service_name.includes("LeadForge") && (
            <VideoModal
              videoSrc="/images/video/LeadForgeyoutubesize.mp4"
              bgUrl="/images/Home-page-lead-forge-thumbnail.jpg"
            />
          )}

        <Spacing lg="50" md="40" />

        <Div className="row">
          <Div className="col-lg-12">
            {individualService &&
              individualService.service_name !== "content-creation" &&
              individualService.service_name !==
              "digital-media-buying-agency" &&
              individualService.service_name !== "social-media-management" &&
              individualService.service_name !== "360-campaigns" &&
              individualService.service_name !==
              "lead-generation-and-acquisition-" &&
              individualService.service_name !== "research-and-development" &&
              individualService.service_name !==
                "character-creation-2d-and-3d" &&
              individualService.service_name !== "branding" &&
              individualService.service_name !== "programmatic-advertising" &&
              individualService.service_name !== "UI-UX-Design" && 
              individualService.service_name !== "Data Mining" &&
              individualService.service_name !== "Advisory Consulting" && (
                <>
                  <SectionHeading
                    mainTitle={
                      individualService && individualService.service_name
                    }
                    subtitle={
                      individualService && individualService.category_name
                    }
                  >
                    <Spacing lg="40" md="20" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          individualService && individualService.description,
                      }}
                    ></p>
                  </SectionHeading>
                </>
              )}
          </Div>

          {individualService &&
            (individualService.service_name === "content-creation" && params.serviceDetailsId == "content-creation" )&& (
              <>
                <Helmet>
                      <title>Content Creation Agency | Product Photography & Videography</title>
                      <meta
                        name="description"
                        content="Enhance your brand with expert content creation services in Dubai. The Impression offers high-quality photo, videography, and creative visual solutions."
                      />
                    </Helmet>
                <ContentCreationPage />
              </>
            )}

          {individualService &&
            (individualService.service_name ===
              "digital-media-buying-agency" || params.serviceDetailsId == "digital-media-buying-agency") && (
              <>
               <Helmet>
                          <title>Digital Media Buying Agency | The Impression</title>
                          <meta
                            name="description"
                            content="Maximize your ROI with The Impression, a leading digital media buying agency in Dubai. Expert ad placements to reach your audience and drive results."
                          />
                        </Helmet>
                <DigitalStatergy />
              </>
            )}

          {individualService &&
            (individualService.service_name === "social-media-management" || params.serviceDetailsId == "social-media-management") && (
              <>
               <Helmet>
                              <title>Social Media Management Agency | Social Media Marketing</title>
                              <meta
                              name="description"
                              content="Boost your brand's online presence with expert social media management services by The Impression in Dubai. Engage your audience and drive business growth."
                              />
                        </Helmet>
                <SocialMediaManagement />
              </>
            )}

          {individualService &&
            (individualService.service_name === "360-campaigns" || params.serviceDetailsId == "360-campaigns") && (
              <>
                  <Helmet>
                          <title>360 Digital Marketing Agency | 360 Marketing Services</title>
                            <meta
                             name="description"
                            content="Explore 360 Campaign services at The Impression in Dubai. Deliver impactful, cohesive brand messages across multiple platforms to engage your audience."
                            />
                      </Helmet>
                <Campaigns />
              </>
            )}

          {individualService &&
            (individualService.service_name ===
              "lead-generation-and-acquisition-" || params.serviceDetailsId == "lead-generation-and-acquisition")  && (
              <>
                <Helmet>
                            <title>Lead Generation Companies | Lead Generation Services In Duabi</title>
                                 <meta
                                  name="description"
                                 content="Boost your business with expert lead generation and acquisition services from The Impression. Drive growth and connect with your ideal audience effectively."
                                 />
                </Helmet>
                <LeadGeneration />
              </>
            )}

          {individualService &&
            (individualService.service_name === "research-and-development" || params.serviceDetailsId == "research-and-development") && (
              <>
                <Helmet>
                              <title>Research & Development | The Impression</title>
                              <meta
                                name="description"
                                 content="Explore innovative research and development services at The Impression. Unlock growth with data-driven insights and tailored strategies for your business."
                              />
                        </Helmet>
                <ResearchAndDevelopment />
              </>
            )}

          {individualService &&
            (individualService.service_name ===
              "character-creation-2d-and-3d" || params.serviceDetailsId == "character-creation-2d-and-3d") && (
              <>
                <Helmet>
                         <title>Character Creation | 2D & 3D Character Creator</title>
                        <meta
                         name="description"
                        content="Explore professional 2D & 3D character creation services at The Impression. Bring your ideas to life with stunning, custom-designed characters in Dubai."
                           />
                     </Helmet>
                <CharacterCreation />
              </>
            )}

          {individualService &&
            (individualService.service_name === "branding" || params.serviceDetailsId == "branding") && (
              <>
               <Helmet>
                            <title>Creative Branding Agency | No1 Branding Agency In Duabi</title>
                            <meta
                             name="description"
                            content="Elevate your brand with expert branding services from The Impression in Dubai. Build a strong identity and connect with your audience effectively."
                               />
                         </Helmet>
                <Branding />
              </>
            )}

          {individualService &&
            (individualService.service_name === "programmatic-advertising" || params.serviceDetailsId == "programmatic-advertising") && (
              <>
               <Helmet>
                                <title>Best Programmatic Advertising Agency | The Impression</title>
                                <meta
                                 name="description"
                                content="Unlock targeted advertising with The Impression's programmatic advertising services in Dubai. Automate, optimize, and reach your audience effectively."
                                   />
                       </Helmet>
                <ProgrammaticAdvertising />
              </>
            )}

          {individualService &&
            (individualService.service_name === "UI-UX-Design" || params.serviceDetailsId == "ui-ux-design")&& (
              <>
                     <Helmet>
                              <title>UI-UX Design Solutions | The Impression</title>
                              <meta
                              name="description"
                              content="Explore The Impression's UI/UX Design services. Elevate your digital presence with intuitive, user-focused designs that captivate and engage."
                                />
                    </Helmet>
                <UiUxDesign />
              </>
            )}

            {/* For d2G Services */}

            {individualService &&
            (individualService.service_name === "Data Mining" && params.serviceDetailsId == "data-mining")&& (
              <>
                <DataMining/>
              </>
            )}
            {individualService &&
            (individualService.service_name === "Advisory Consulting" || params.serviceDetailsId == "advisory-consulting") && (
              <>
                <AdvisoryConsulting/>
              </>
            )}

          {individualService &&
            individualService.service_name == "LeadForge" && (
              <Div className="col-lg-5">
                <Spacing lg="60" md="40" />
                <h2 style={{ marginRight: 30 }} className="light_pink">
                  Trusted By
                </h2>
                <img
                  src="/images/Meirc.jpg"
                  alt="meirc"
                  className="trustedby_img"
                />
              </Div>
            )}

          <LeadforgeContact />
          {/* <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40' />
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Service Info -</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
             
              {individualService && individualService.additionalinfo && individualService.additionalinfo.map((item, i) => (
                item.key !== '' && <Div className="col-6" key={i}>
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>{item.key}:</h3>
                  <p className='cs-m0'>{item.answer}</p>
                  <Spacing lg='30' md='30' />
                </Div>
              ))}
            </Div>
          </Div> */}
        </Div>

        <Div className="text-center mt-4">
          <Button onClick={handledownloadOpen} className="theme_btn_new">
            Download Brochure
          </Button>
        </Div>
        {/* <Div className="text-center mt-4">
          <Button onClick={handleClickOpen}  className="theme_btn_new">Download Our Portfolio</Button>
        </Div> */}
      </Div>

      <Spacing lg="20" md="20" />

      {/* <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1 FAQ_section">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div> */}

      {/* <Spacing lg='100' md='80' /> */}
      {/* <Cta
        title='ramy@theimpression.me'
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      /> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="custom_modal"
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          className="modal_title"
          id="customized-dialog-title"
        >
          <div> Get a Quote </div>{" "}
          <div className="ml-auto">
            <Icon icon="mdi:close" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form className="row">
            <Div className="col-sm-6">
              <label className="cs-primary_color">First Name*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.firstname}
                name="firstname"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.firstname}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Last Name </label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.lastname}
                name="lastname"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Company</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.company}
                name="company"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Email*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.email}
                name="email"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.email}</p>
              <Spacing lg="20" md="20" />
            </Div>

            <Div className="col-sm-6">
              <label className="cs-primary_color">Phone Number*</label>
              <PhoneInput
                value={contactdetl.phonenumber}
                className="cs-form_field"
                defaultCountry="LB"
                onChange={(e) => {
                  setContactdetl({ ...contactdetl, phonenumber: e });
                  setError({ ...errror, phonenumber: "" });
                }}
              />
              <p style={{ color: "red" }}> {errror.phonenumber}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <h5 className={colordata == "dark" ? "" : "contacttect"}>
              What services are you interested in?
            </h5>

            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="branding"
                    checked={serviceavlb.branding}
                    onChange={handleServicedetail}
                  />
                }
                label="Branding"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="campaigns_360"
                    checked={serviceavlb.campaigns_360}
                    onChange={handleServicedetail}
                  />
                }
                label="360 Campaigns"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="content_creation"
                    checked={serviceavlb.content_creation}
                    onChange={handleServicedetail}
                  />
                }
                label="Content Creation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="digital_strategy_media_buying"
                    checked={serviceavlb.digital_strategy_media_buying}
                    onChange={handleServicedetail}
                  />
                }
                label="Digital Strategy & Media Buying"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="social_media_management"
                    checked={serviceavlb.social_media_management}
                    onChange={handleServicedetail}
                  />
                }
                label="Social Media Management"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="research_development"
                    checked={serviceavlb.research_development}
                    onChange={handleServicedetail}
                  />
                }
                label="Research & Development"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="data_mining"
                    checked={serviceavlb.data_mining}
                    onChange={handleServicedetail}
                  />
                }
                label="Data Mining"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="lead_generation"
                    checked={serviceavlb.lead_generation}
                    onChange={handleServicedetail}
                  />
                }
                label="Lead Generation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="websites_applications"
                    checked={serviceavlb.websites_applications}
                    onChange={handleServicedetail}
                  />
                }
                label="BrandWebsites & Applicationsing"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="advisory_consulting_services"
                    checked={serviceavlb.advisory_consulting_services}
                    onChange={handleServicedetail}
                  />
                }
                label="Advisory & Consulting Services"
              />
            </Div>
          </form>
        </DialogContent>
        <DialogActions>
          {btndisable ? (
            <button className="cs-btn cs-style1">
              <span>Please wait...</span>
            </button>
          ) : (
            <button className="cs-btn cs-style1" onClick={handleSubmit}>
              <span>Send Message</span>
              <Icon icon="bi:arrow-right" />
            </button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}

