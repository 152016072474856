import React from 'react';
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionDataLeadGeneration } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";


const LeadGeneration = () => {
  return (

     <>
            <Helmet>
                 <title>Lead Generation Companies | Lead Generation Services In Duabi</title>
                   <meta
                    name="description"
                   content="Boost your business with expert lead generation and acquisition services from The Impression. Drive growth and connect with your ideal audience effectively."
                   />
             </Helmet>
      <SectionHeading
        mainTitle="Lead Generation And Acquisition"
        caption="Transforming Lead Generation for Business Growth"
        para="Strategically harnessing digital platforms to capture and nurture potential customer leads. Our approach integrates advanced targeting, buyer intent analysis, and conversion-focused strategies to turn prospects into loyal customers."
      ></SectionHeading>
     

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle="Driving Results with Comprehensive Lead Acquisition"
            para='What We Offer in Lead Generation'
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Targeted Campaigns:</h4>{" "}
                  Leveraging PPC advertising and SEO for precise audience engagement.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}> Data-Driven Insights:</h4> Using analytics and buyer intent research to refine targeting and improve lead quality.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Lead Nurturing Strategies:</h4>{" "} Engaging potential clients through personalized content and consistent communication.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Multi-Channel Approach:</h4>Integrating Google Ads, social media, and email marketing for maximum reach.
                </li>
               
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />


      

      <Div className="row">
        <SectionHeading
          caption='Your Trusted Partner in Digital Lead Acquisition'
          para=" Why Choose The Impression for Lead Generation?"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Precision Targeting:</h4>  Using advanced tools to identify high-potential leads across platforms.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Analytics Expertise:</h4>  Transforming data into actionable insights for optimized campaigns.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Scalable Solutions:</h4>  From small-scale to enterprise campaigns, we deliver results that grow with your business.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Cross-Industry Expertise:</h4> Proven success in real estate, e-commerce, hospitality, and more.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}> Local Market Knowledge:</h4> Tailored strategies for Dubai’s competitive business environment.

              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="100" md="80" />

      {/* Testimonials */}
      
      {/* <Div className="container">
        <SectionHeading subtitle="Your Questions About Lead Generation Answered" />
        <Div 
        className="row"
        >
          <Div 
            className={
                accordionDataLeadGeneration.length > 7 ? "col-xl-5 col-lg-6" : ""
            }
          >
            <Accordion name="LeadGeneration" />
          </Div>
          {accordionDataLeadGeneration.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="LeadGeneration" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>

  )
}

export default LeadGeneration