import React, { useEffect, useState } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Pagination from '../Pagination'
import PostStyle2 from '../Post/PostStyle2'
import Div from '../Div'
import Sidebar from '../Sidebar.jsx'
import Spacing from '../Spacing'
import axios from 'axios'
import { Icon } from '@iconify/react'
import Loader from '../Loader/index.js'
import { Helmet } from "react-helmet";

export default function BlogPage() {
  pageTitle('Blog');
  const [itemShow, setItemShow] = useState(4);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    getBlogs()
  }, [])
  const [blogs, setBlogs] = useState([])

  const getBlogs = (e) => {
    setLoader(true)
    axios.get(process.env.REACT_APP_BASE_URL + `blog/getBlogsPublic`).then((res) => {
      setBlogs(res.data.getBlogs)
      setLoader(false)
    }).catch((error) => {
      console.log(error, "error");
      setLoader(false)
    });
  }

  if (loader === true) {
    return <Loader />
  }

  return (
    <>
    
      <PageHeading
        title='Our Blog'
        bgSrc='/images/blog_hero_bg.jpeg'
        pageLinkText='Blog'
      />
       <Helmet>
              <meta name="robots" content="noindex, nofollow" />
            </Helmet>
      <Spacing lg='80' md='40' />
      <Div className="container blog_page">
        {blogs && blogs.length > 0 ?
          <Div className="row">
            <Div className="col-lg-8">
              {blogs && blogs.slice(0, itemShow).map((item, index) => (
                <Div key={index}>
                  <PostStyle2
                    img={item.Images}
                    title={item.Blog_Name}
                    shortDesc={item.shortdescription}
                    Desc={item.Description}
                    date={item.createdAt}
                    category={item.Category && item.Category.category_name}
                    quote={item.blogqoute}
                    categoryHref={'/blog'}
                    href={'/blog/blog-details'}
                  />
                  {blogs && blogs.length > index + 1 && <Spacing lg='95' md='60' />}
                </Div>
              ))}
              <Spacing lg='60' md='40' />
              {/* <Pagination /> */}
              <Div className="text-center">
                {blogs && blogs.length <= itemShow ? (
                  ''
                ) : (
                  <>
                    <Spacing lg="65" md="40" />
                    <span
                      className="cs-text_btn"
                      onClick={() => setItemShow(itemShow + 2)}
                    >
                      <span>Load More</span>
                      <Icon icon="bi:arrow-right" />
                    </span>
                  </>
                )}
              </Div>
            </Div>
            <Div className="col-xl-3 col-lg-4 offset-xl-1">
              <Spacing lg='0' md='80' />
              <Sidebar />
            </Div>
          </Div> : <>
            <h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No Blogs found</h2>
            <Spacing lg="90" md="45" />
          </>}
      </Div>
      <Spacing lg='80' md='40' />
      <Div className="container">
        <Cta
          // title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
          title="Let’s Create <br />something Remarkable!"
          btnText="Book a Meeting Now"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  )
}
