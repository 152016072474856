import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title, contactdetails }) {

  return (
    <>
      {title && <h4 className="cs-widget_title">{title}</h4>}
      <ul className="cs-menu_widget cs-style1 cs-mp0 d-flex address_col">
        {/* <li>
      
      
          {contactdetails && contactdetails.phone ?
          <span className='cs-accent_color'> <a href="tel:+971562117034" ><Icon icon="material-symbols:add-call-rounded" /></a> </span>: ''}
          <a href={`tel:${contactdetails && contactdetails.phone}`} >{contactdetails && contactdetails.phone}</a>

        </li>
        <li>
          {contactdetails && contactdetails.email ?<span className='cs-accent_color'> <a href="mailto:ramy@theimpression.me" ><Icon icon="mdi:envelope" /></a></span>  : ''}         
          <a href={`mailto:${contactdetails && contactdetails.email}`} >{contactdetails && contactdetails.email}</a>
          
        </li>
        <li>
          {contactdetails && contactdetails.address ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
          {contactdetails && contactdetails.address}
        </li> */}
        <div className='pe-2'>
          <h5 style={{ textDecoration: 'underline' }}>Dubai Address:</h5>
          <li>
            {contactdetails[0] && contactdetails[0].email ? <span className='cs-accent_color'> <a href="mailto:hello@theimpression.me" ><Icon icon="mdi:envelope" /></a></span> : ''}
            <a href="mailto:hello@theimpression.me" >{contactdetails[0] && contactdetails[0]?.email}</a>
          </li>
          <li>
            {contactdetails[0] && contactdetails[0].phone ? <span className='cs-accent_color'>  <a href="tel:+96181410100" ><Icon icon="material-symbols:add-call-rounded" /></a> </span> : ''}
            <a href="tel:+971562117034" >{contactdetails[0] && contactdetails[0]?.phone}</a>
          </li>

          <li>
            {contactdetails[0] && contactdetails[0].address ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}

            {contactdetails[0] && contactdetails[0].address}
          </li>
        </div>
        <div >
          <h5 style={{ textDecoration: 'underline' }}>Lebanon Address:</h5>
          <li>
            {contactdetails[2] && contactdetails[2].email ? <span className='cs-accent_color'> <a href="mailto:hello@theimpression.me" ><Icon icon="mdi:envelope" /></a></span> : ''}
            <a href="mailto:hello@theimpression.me" >{contactdetails[2] && contactdetails[2]?.email}</a>
          </li>
          <li>
            {contactdetails[2] && contactdetails[2].phone ? <span className='cs-accent_color'>  <a href="tel:+96181410100" ><Icon icon="material-symbols:add-call-rounded" /></a> </span> : ''}
            <a href="tel:+971562117034" >{contactdetails[2] && contactdetails[2]?.phone}</a>
          </li>

          <li>
            {contactdetails[2] && contactdetails[2].address ? <span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span> : ''}
            {contactdetails[2] && contactdetails[2].address}
          </li>
        </div>
      </ul>
    </>
  )
}
