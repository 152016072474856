import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";

import FaqforCC from "../FaqforCC";
import { accordionDataBranding, accordionDataDataMining } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";

const DataMining = () => {
    return (
      <>
        <SectionHeading
          mainTitle="Data Mining"
          caption="Unlocking Insights Through Data Mining"
          subtitle="Empowering Decisions with Data-Driven Insights"
          para="Data mining transforms complex datasets into actionable insights, enabling businesses to make informed decisions, refine strategies, and stay ahead in competitive markets. By leveraging advanced techniques, we uncover hidden patterns and trends to guide your success"
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              subtitle="What We Offer in Data Mining"
              para="Comprehensive Data Mining Solutions"
            >
            
                <ul className="space-y-6">
                  <li>
                    <h4 className="text-xl font-bold mb-2">
                      Pattern Discovery:
                    </h4>
                    <p className="text-base">
                      Identifying trends and correlations in large datasets to
                      predict outcomes.
                    </p>
                  </li>
                  <Spacing lg="25" md="25" />

          <li>
            <h4 className="text-xl font-bold mb-2">Predictive Analytics:</h4>
            <p className="text-base">
              Using data models to forecast market trends and customer behavior.
            </p>
          </li>
          <Spacing lg="25" md="25" />

          <li>
            <h4 className="text-xl font-bold mb-2">Data Cleansing:</h4>
            <p className="text-base">
              Ensuring your data is accurate, relevant, and ready for analysis.
            </p>
          </li>
          <Spacing lg="25" md="25" />

          <li>
            <h4 className="text-xl font-bold mb-2">Visualization Tools:</h4>
            <p className="text-base">
              Presenting complex data in intuitive charts and dashboards.
            </p>
          </li>
          <Spacing lg="25" md="25" />

          <li>
            <h4 className="text-xl font-bold mb-2">Custom Reports:</h4>
            <p className="text-base">
              Delivering insights tailored to your industry's needs and challenges.
            </p>
          </li>
          </ul>
            
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="30" md="20" />

        <Div className="row">
          <SectionHeading
            caption="Industries We Serve"
            para="Driving Innovation Across Diverse Sectors"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Healthcare:</h4> Analyzing patient data to enhance medical
                  services and improve care.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Real Estate:</h4> Identifying market trends and property
                  valuation patterns for investment.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>E-commerce:</h4> Understanding consumer behavior to
                  optimize product offerings and pricing.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Hospitality:</h4> Forecasting demand and enhancing guest
                  experiences through trend analysis.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Education:</h4> Evaluating student data to improve
                  learning outcomes and engagement.
                </li>

                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Insurance:</h4> Enhancing risk assessment and client
                  acquisition strategies.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Technology:</h4> Optimizing product development and user
                  experience with detailed insights.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Retail:</h4> Driving sales and improving customer
                  retention with personalized marketing strategies.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        <Div className="row">
          <SectionHeading
            caption=" Why Choose The Impression for Data Mining?"
            para="Turning Data into Strategic Advantage"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Expert Techniques:</h4> Advanced tools and methods for
                  accurate data analysis.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Tailored Insights:</h4> Reports customized to address your
                  business goals and challenges.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Proven Success:</h4> Track record of enabling smarter
                  decisions across industries.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Scalable Solutions:</h4> Flexible services that grow with
                  your business needs.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Ethical Practices:</h4> Ensuring data privacy and
                  compliance in every project.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        {/* Testimonials */}

        {/* <Div className="container">
          <SectionHeading
            subtitle="FAQs About Data Mining"
            para="Your Questions About Data Mining, Answered"
          />
          <Div className="row">
            <Div
              className={
                accordionDataDataMining.length > 7 ? "col-xl-5 col-lg-6" : ""
              }
            >
              <Accordion name="DataMining" />
            </Div>

            {accordionDataDataMining.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="DataMining" isSecondColumn={true} />
              </Div>
            )}
          </Div>
        </Div> */}
      </>
    );
}

export default DataMining