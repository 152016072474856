import React from 'react'
import parse from 'html-react-parser';
import Button from '../Button'
import Spacing from '../Spacing'
import Div from '../Div';

export default function SectionHeading({mainTitle,title, subtitle, caption,para,btnLink, btnText, variant, children}) {

  return (
    <Div className={variant ? `cs-section_heading ${variant}` : `cs-section_heading cs-style1`}>

      {mainTitle && <h1 className="cs-section_title">{mainTitle && parse(mainTitle)}</h1>}

      <Spacing lg='20' md='20'/>


      {caption && <h2 className="cs-section_subtitle">{caption && parse(caption)}</h2>}
      {subtitle && <h3 className="cs-section_subtitle">{subtitle && parse(subtitle)}</h3>}
      
      

      {/* <h1 className="cs-section_title">{title && parse(title)}</h1> */}


      {para && (
      <p>
        {parse(
          para.replace(
            "digital marketing in the UAE", 
            '<a href="https://theimpression.me/" target="_blank" rel="noopener noreferrer" style="color:rgb(238 45 249); text-decoration: underline;">digital marketing in the UAE</a>'
          )
        )}
      </p>
    )}


      
      {/* <h3 className="cs-section_subtitle">{subtitle && subtitle == 'servicepageview'?"": parse(subtitle)}</h3>
  <h2 className={subtitle == 'servicepageview'? "cs-section_titlenew" :"cs-section_title"}>{title && parse(title)}</h2> */}
      {children}

      {btnText && (
        <>
          <Spacing lg='45' md='20'/>
          {/* <Button btnLink={btnLink} btnText={btnText}/> */}
        </>
      )}
    </Div>
  )
}
