
import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import GalleryPortfolio from '../Portfolio/portfolioGallery';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Loader from '../Loader';
import Slider from 'react-slick';
import './gallery.css';
import { Helmet } from "react-helmet";

const GallerypageDetails = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(false);
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const primaryImage = "images/portfolio_35.jpeg";
    const additionalImages = [
        "images/portfolio_38.jpeg",
        "images/portfolio_37.jpeg",
        "images/portfolio_36.jpeg",

    ];
    console.log(selectedImageIndex, 'selectedImageIndex');
    const openImage = () => {
        setSelectedImageIndex(true); // Set selected image index to show the modal with the primary image initially
    };

    const openAdditionalImage = (index) => {
        setSelectedImageIndex(index + 1); // Set selected image index to show the modal with the additional image
    };

    const closeImage = () => {
        setSelectedImageIndex(null); // Close the modal by resetting the selected image index
    };

    return (
        <Div className="Project_main">
            <PageHeading
                title=""
                bgSrc="images/portfolio_hero_bg.jpeg"
                pageLinkText="Our Work"
            />
            <Helmet>
                 <meta name="robots" content="noindex, nofollow" />
            </Helmet> 


            <div className="image-gallery">
                <div className="card" onClick={openImage}>
                    <img src={primaryImage} alt="Primary Image" />
                </div>

                {selectedImageIndex && 
                    <div className="overlay" onClick={closeImage}>
                        <div >
                            <button onClick={closeImage}>Close</button>
                            <Slider {...settings} initialSlide={selectedImageIndex}>
                                {additionalImages.map((image, index) => (                                    
                                    <div key={index}>
                                        <img src={image} alt={`Additional Image ${index + 1}`} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                }
            </div>


            <Spacing lg="80" md="40" />
            <Div className="container">
                <Cta
                    // title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
                    title="Let’s Create <br />something Remarkable!"
                    btnText="Book a Meeting Now"
                    btnLink="/contact"
                    bgSrc="/images/cta_bg.jpeg"
                />
            </Div>
        </Div>


    );
};

export default GallerypageDetails;
