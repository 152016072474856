import React from 'react'
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'

import FaqforCC from '../FaqforCC'
import { accordionDataContentCreation } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";


const ContentCreationPage = () => {
    const colordata = useSelector((state) => state && state.colordata)
  return (
    <>
     <Helmet>
        <title>Content Creation Agency | Product Photography & Videography</title>
        <meta
          name="description"
          content="Enhance your brand with expert content creation services in Dubai. The Impression offers high-quality photo, videography, and creative visual solutions."
        />
      </Helmet>
      <SectionHeading
        mainTitle="Content Creation – Photo and videography"
        caption="High-Quality Content Creation Services in Dubai"
        para="We create impactful, high-quality digital content for businesses in Dubai. From insurance PPC campaigns to e-commerce advertising and SEO for restaurants, our strategies drive engagement and deliver measurable results, helping your brand thrive in a competitive market."
      ></SectionHeading>

      <Div className="row">
        <Div className="col-xl-5 col-lg-7">
          <SectionHeading
            subtitle="Comprehensive Content Creation for Businesses in Dubai"
            para='Break down your services into categories such as:'
          >
            <Box>
              <ul>
                <li>
                  <h4>Video Production:</h4>{" "}
                  Producing impactful promotional videos tailored for insurance
                  PPC campaigns and e-commerce advertising.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Photography:</h4> Capturing
                  high-quality product photography and event coverage for real
                  estate marketing services and hospitality campaigns.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Graphic Design:</h4>{" "}
                  Designing custom branding, infographics, and marketing
                  materials for SEO for insurance brokers and restaurant digital
                  marketing.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 >Copywriting:</h4>Writing
                  SEO-optimized blogs, ad copies, creative content, and website
                  content for e-commerce SEO services and healthcare promotion
                  campaigns.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4>Social Media Content:</h4>{" "}
                  Creating dynamic social media posts for platforms like
                  Instagram and LinkedIn, optimized for F&B digital marketing
                  services and insurance client acquisition.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Div className="col-lg-5 offset-xl-2">
          <img
            src="/images/about_us.png"
            alt="About"
            className="w-100 cs-radius_15"
          />
          <Spacing lg="25" md="25" />
        </Div>
      </Div>
      <Spacing lg="30" md="20" />


      <Div className="row">
        <SectionHeading
          caption="Industries We Support with Tailored Content Solutions"
          para="We proudly serve a variety of industries, including insurance, e-commerce, hospitality, real estate, F&B, education, and healthcare. Our expertise spans SEO for insurance brokers, PPC for e-commerce websites, restaurant customer acquisition, and digital campaigns for real estate developers, delivering tailored solutions to meet industry-specific needs."
        ></SectionHeading>
      </Div>

      <Div className="row">
        <SectionHeading
          caption="Why Choose Us for Your Content Creation Needs"
          subtitle="We have extensive experience delivering impactful results across a variety of industries:"
        >
          <Box>
            <ul>
              <li>
                <h4 style={{ display: "inline" }}>Experienced Team:</h4> A
                skilled group of content creators and strategists with a proven
                record in insurance marketing services, e-commerce advertising
                campaigns, and more.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Local Expertise:</h4> Deep
                understanding of Dubai’s unique cultural and business
                environment, allowing us to craft effective campaigns for
                hospitality and real estate marketing services.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Advanced Tools:</h4> Use of
                cutting-edge technology for SEO for property websites, Google
                Ads for medical services, and digital ads for catering.
              </li>
              <Spacing lg="25" md="25" />

              <li>
                <h4 style={{ display: "inline" }}>Proven Success:</h4> A track
                record of delivering results across industries, including
                tourism, healthcare, F&B digital marketing, and education PPC
                advertising.
              </li>
            </ul>
          </Box>
        </SectionHeading>
      </Div>

      <Spacing lg="100" md="80" />

      {/* Testimonials */}
      
      {/* <Div className="container">
        <SectionHeading subtitle="Frequently Asked Questions About Content Creation Services" />
        <Div 
        className="row"
        >
          <Div 
            className={
              accordionDataContentCreation.length > 7 ? "col-xl-5 col-lg-6" : ""
            }
          >
            <Accordion name="ContentCreation" />
          </Div>

          {accordionDataContentCreation.length > 7 && (
            <Div className="col-xl-5 col-lg-6">
              <Accordion name="ContentCreation" isSecondColumn={true} />
            </Div>
          )}
        </Div>
      </Div> */}
    </>
  );
}

export default ContentCreationPage;


// Backup part

{/* <h2
                      className="cs-m0"
                      style={{ color: colordata == "light" ? "#000" : "" }}
                    >
                      Driving brand futures through innovation, individuality,
                      and social responsibility.
                    </h2>
                    <Spacing lg="15" md="15" />
                    <p
                      className="cs-m0"
                      style={{ color: colordata == "light" ? "#000" : "" }}
                    >
                      The Impression is at the forefront of digital evolution.
                      We're committed to evolving international businesses,
                      forging impactful partnerships, and delivering growth
                      across all sectors.
                    </p>
                    <Spacing lg="30" md="30" />
                    <Div className="cs-separator cs-accent_bg"></Div>
                    <Spacing lg="25" md="40" /> */}

// Testimonail

{/* 
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading title="Why Partner with Us?" subtitle="">
              <Spacing lg="30" md="20" />
              <p
                className="cs-m0"
                style={{ color: colordata == "light" ? "#000" : "" }}
              >
                Our approach is holistic and data-driven, ensuring that every
                strategy is grounded in real-world insights and tailored to your
                specific goals. We're not just consultants; we're your strategic
                partners, dedicated to navigating the complexities of the
                digital landscape together.
              </p>
              <Spacing lg="15" md="15" />
              <p
                className="cs-m0"
                style={{ color: colordata == "light" ? "#000" : "" }}
              >
                With us, you gain more than just a service provider; you gain
                allies committed to your success. Our track record speaks for
                itself: a history of transforming challenges into opportunities
                and businesses into benchmarks of their industries.
              </p>
              <Spacing lg="15" md="15" />
              <p style={{ color: colordata == "light" ? "#000" : "" }}>
                Choose us and let's contribute to your Long and Lasting
                IMPRESSION.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div> */}
