import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";

import FaqforCC from "../FaqforCC";
import { accordionDataAdvisoryConsulting, accordionDataBranding, accordionDataDataMining } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";

const AdvisoryConsulting = () => {
    return (
      <>
        <SectionHeading
          mainTitle="Advisory Consulting"
          caption="Strategic Advisory Consulting"
          para="Our advisory consulting services offer actionable strategies aligned with digital trends and industry needs. From improving infrastructures to enhancing engagement, we help businesses and governments achieve efficient and innovative solutions."
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              subtitle="What We Offer in Advisory Consulting"
              para="Comprehensive Solutions for Strategic Growth"
            >
              <Box>
                <ul>
                  <li>
                    <h4>Digital Transformation Strategies:</h4> Aligning
                    operations with cutting-edge digital trends for efficiency.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Process Optimization:</h4> Streamlining workflows to
                    reduce complexity and improve productivity.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Infrastructure Development:</h4> Enhancing digital
                    frameworks to meet evolving demands.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Citizen Engagement Programs:</h4> Tailoring initiatives
                    to foster community involvement and satisfaction.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Policy and Governance Insights:</h4> Providing
                    data-backed guidance for impactful decision-making.
                  </li>
                </ul>
              </Box>
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="30" md="20" />

        <Div className="row">
          <SectionHeading
            caption="IIndustries We Serve"
            para="Driving Success Across Key Sectors"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Government:</h4> Enhancing digital governance, citizen
                  services, and infrastructure planning.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Healthcare:</h4> Advising on digital solutions for patient
                  management and operational efficiency.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Education:</h4> Streamlining digital platforms for better
                  learning outcomes and accessibility.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Real Estate:</h4> Optimizing property management and urban
                  planning strategies.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Technology:</h4> Facilitating innovation and scalability
                  for emerging tech solutions.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>E-commerce:</h4> Offering insights to refine operations
                  and improve customer experiences.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Hospitality:</h4> Developing digital strategies for guest
                  engagement and service optimization.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        <Div className="row">
          <SectionHeading
            caption="Why Choose The Impression for Advisory Consulting?"
            para="Guiding Your Vision with Proven Expertise"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Tailored Solutions:</h4> Strategies customized to meet
                  your specific goals and challenges.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Innovative Insights:</h4> Guidance rooted in the latest
                  trends and technologies.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Proven Track Record:</h4> Success stories across diverse
                  sectors.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Collaborative Approach:</h4> Partnering closely with you
                  to ensure alignment and success.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Scalable Support:</h4> Solutions that adapt to your
                  organization’s growth and evolution.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        {/* Testimonials */}

        {/* <Div className="container">
          <SectionHeading
            subtitle="FAQs About Data Mining"
            para="Your Questions About Data Mining, Answered"
          />
          <Div className="row">
            <Div
              className={
                accordionDataAdvisoryConsulting.length > 7 ? "col-xl-5 col-lg-6" : ""
              }
            >
              <Accordion name="AdvisoryConsulting" />
            </Div>
            {accordionDataAdvisoryConsulting.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="AdvisoryConsulting" isSecondColumn={true} />
              </Div>
            )}
          </Div>
        </Div> */}
      </>
    );
}

export default AdvisoryConsulting