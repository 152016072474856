import React from "react";
import SectionHeading from "../../SectionHeading";
import Div from "../../Div";
import Spacing from "../../Spacing";
import { useSelector } from "react-redux";
import Accordion from "../../Accordion";

import FaqforCC from "../FaqforCC";
import { accordionDataBranding, accordionDataLeadForge } from "../../Accordion/AccordionData";
import { Box } from "@mui/material";



const LeadforgeContent = () => {
    return (
        <>
            <Div className="row ">
                <Div className="col-xl-5 col-lg-7">
                    <SectionHeading
                        subtitle="What Makes LeadForge Exceptional"
                        para="Features to Supercharge Your Sales Funnel"
                    ><Box>
                            <ul>
                                <li>
                                    <h4>Advanced Buyer Intent Research:</h4> Pinpoint
                                    prospective leads based on behavior and preferences.
                                </li>
                                <Spacing lg="25" md="25" />

                                <li>
                                    <h4>Data-Driven Insights:</h4> Leverage data mining to
                                    uncover what decision-makers are searching for.
                                </li>
                                <Spacing lg="25" md="25" />

                                <li>
                                    <h4>Integrated Communication Strategies:</h4> Utilize email
                                    marketing and cold calling to build and nurture
                                    relationships.
                                </li>
                                <Spacing lg="25" md="25" />

                                <li>
                                    <h4>Corporate Lead Database:</h4> Access detailed corporate
                                    profiles to target key decision-makers.
                                </li>
                                <Spacing lg="25" md="25" />

                                <li>
                                    <h4>Conversion Optimization:</h4> Tailor strategies for
                                    high-impact engagement and increased ROI.
                                </li>
                            </ul>
                        </Box>
                    </SectionHeading>
                    <Spacing lg="30" md="20" />

                    <Div className="row">
                        <SectionHeading
                            caption="Benefits of LeadForge"
                            para="Drive Growth and Conversions"
                        >
                            <Box>
                                <ul>
                                    <li>
                                        <h4 style={{ display: "inline" }}>Precision Targeting:</h4> Identify high-value leads with
                                        advanced data insights.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Enhanced Engagement:</h4> Foster meaningful interactions
                                        through personalized communication.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Increased Efficiency:</h4> Save time with automated tools
                                        and focused strategies.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Scalable Solutions:</h4> Adaptable to businesses of all
                                        sizes and industries.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Measurable Impact:</h4> Track performance to continually
                                        refine and improve results.
                                    </li>
                                </ul>
                            </Box>
                        </SectionHeading>
                    </Div>

                    <Spacing lg="100" md="80" />

                    <Div className="row">
                        <SectionHeading
                            caption="Industries Benefiting from LeadForge"
                            para="Results Across Diverse Sectors"
                        >
                            <Box>
                                <ul>
                                    <li>
                                        <h4 style={{ display: "inline" }} >Real Estate:</h4> Streamlining lead generation for
                                        property buyers and investors.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >E-commerce:</h4> Identifying potential customers and
                                        improving conversion rates.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >Healthcare:</h4> Connecting with healthcare providers and
                                        suppliers.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >Insurance:</h4> Engaging clients with targeted insurance
                                        digital services and marketing.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >Technology:</h4> Building relationships with tech
                                        decision-makers through data-driven insights.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >Hospitality:</h4> Enhancing lead generation for tourism
                                        and guest services.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }} >Education:</h4> Reaching students and institutions through
                                        tailored outreach strategies.
                                    </li>
                                </ul>
                            </Box>
                        </SectionHeading>
                    </Div>

                    <Spacing lg="100" md="80" />

                    <Div className="row">
                        <SectionHeading
                            caption="Why Choose LeadForge for Lead Generation?"
                            para="Unmatched Accuracy and Efficiency for Modern Businesses"
                        >
                            <Box>
                                <ul>
                                    <li>
                                        <h4 style={{ display: "inline" }}>Cutting-Edge Tools:</h4> Advanced research and analytics
                                        ensure accurate targeting.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Tailored Strategies:</h4> Customized approaches for unique
                                        business needs.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Expert Support:</h4> Backed by industry experts to
                                        maximize campaign success.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Proven Success:</h4> Trusted by diverse industries for
                                        effective lead generation.
                                    </li>
                                    <Spacing lg="25" md="25" />

                                    <li>
                                        <h4 style={{ display: "inline" }}>Continuous Innovation:</h4> Evolving with the latest
                                        trends and technologies.
                                    </li>
                                </ul>
                            </Box>
                        </SectionHeading>
                    </Div>

                    <Spacing lg="100" md="80" />
                </Div>


                <Div className="col-lg-5 offset-xl-2">
                    <Div> 
                   <img
                        src="/images/about_us.png"
                        alt="About"
                        className="w-100 cs-radius_15"
                    /></Div>
                  
                    <Spacing lg="25" md="25" />
                </Div>
            </Div>



            {/* Testimonials */}

            {/* <Div className="container">
                <SectionHeading
                    subtitle="FAQs About Branding Service"
                    para="Your Questions About Branding Answered"
                />
                <Div className="row">
                
                    <Div
                        className={
                            accordionDataLeadForge.length > 7 ? "col-xl-5 col-lg-6" : ""
                        }
                    >
                        <Accordion name="LeadForge" />
                    </Div>

                    {accordionDataLeadForge.length > 7 && (
                        <Div className="col-xl-5 col-lg-6">
                            <Accordion name="LeadForge" isSecondColumn={true} />
                        </Div>
                    )}
                </Div>
            </Div> */}
        </>
    );
}

export default LeadforgeContent