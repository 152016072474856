import React, { useEffect, useState } from 'react';
import axios from 'axios'
import parse from 'html-react-parser';
import './cta.scss'
// import Button from '../Button/downloadButton';
import Div from '../Div';
import Spacing from '../Spacing';
import { Button } from '@mui/material';
import { useSelector } from "react-redux"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));


export default function Cta({title, btnText, btnLink, bgSrc, variant}) {
  
	const [open, setOpen] = React.useState(false);
  const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [btndisable, setBtndisable] = useState(false)
	const colordata = useSelector((state) => state && state.colordata)

	const [serviceavlb, setServiceavlb] = useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	})

  const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
	}

	const handleClickOpen = () => {
		setOpen(true);
		var element = document.getElementById("html_main");
		element.classList.add("modalOpen");
	};

	const handleClose = () => {
		setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setServiceavlb({
			branding: false,
			campaigns_360: false,
			content_creation: false,
			digital_strategy_media_buying: false,
			social_media_management: false,
			research_development: false,
			data_mining: false,
			lead_generation: false,
			websites_applications: false,
			advisory_consulting_services: false,
		});
		setOpen(false);
		var element = document.getElementById("html_main");
		element.classList.remove("modalOpen");
	};

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		else if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		else if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		else if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		else if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setBtndisable(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
				setBtndisable(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setOpen(false)
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setBtndisable(false)
				console.log(err)
			})
		}
	}
  return (
    <>
    <Div className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1 ${variant?variant:''}`} style={{backgroundImage: `url(${bgSrc})`}}>
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h2 className="cs-cta_title cs-semi_bold cs-m0">{parse(title)}</h2>
        {btnText && (
          <>
            <Spacing lg='70' md='30' />
            {/* <Button
              btnLink={btnLink}
              btnText={btnText}
            /> */}
            <Button onClick={handleClickOpen} className="theme_btn_new">
              <h4 class="m-0">Download Our Portfolio</h4>
            </Button>
          </>
        )}
      </Div>
    </Div>
    <BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				className='custom_modal'
				maxWidth='md'
			>
				<DialogTitle sx={{ m: 0, p: 2 }} className='modal_title' id="customized-dialog-title">
					<div> <h2>Get a Quote</h2>  </div> <div className='ml-auto'>
						<Icon icon="mdi:close" onClick={handleClose} />
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<form className="row">
						<Div className="col-sm-6">
							<label className="cs-primary_color">First Name*</label>
							<input type="text" className="cs-form_field" value={contactdetl.firstname} name='firstname' onChange={handleContactdetail} />
							<p style={{ color: "red" }}> {errror.firstname}</p>
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color">Last Name </label>
							<input type="text" className="cs-form_field" value={contactdetl.lastname} name='lastname' onChange={handleContactdetail} />
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color" >Company</label>
							<input type="text" className="cs-form_field" value={contactdetl.company} name='company' onChange={handleContactdetail} />
							<Spacing lg="20" md="20" />
						</Div>
						<Div className="col-sm-6">
							<label className="cs-primary_color"  >Email*</label>
							<input type="text" className="cs-form_field" value={contactdetl.email} name='email' onChange={handleContactdetail} />
							<p style={{ color: "red" }}> {errror.email}</p>
							<Spacing lg="20" md="20" />
						</Div>

						<Div className="col-sm-6">
							<label className="cs-primary_color">Phone Number*</label>
							<PhoneInput
								value={contactdetl.phonenumber}
								className="cs-form_field"
								defaultCountry="LB"
								onChange={(e) => { setContactdetl({ ...contactdetl, phonenumber: e }); setError({ ...errror, "phonenumber": '' }) }}
							/>
							<p style={{ color: "red" }}> {errror.phonenumber}</p>
							<Spacing lg="20" md="20" />
						</Div>
						<h5 className={colordata == 'dark' ? "" : "contacttect"}>What services are you interested in?</h5>

						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='branding' checked={serviceavlb.branding} onChange={handleServicedetail} />} label="Branding" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='campaigns_360' checked={serviceavlb.campaigns_360} onChange={handleServicedetail} />} label="360 Campaigns" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='content_creation' checked={serviceavlb.content_creation} onChange={handleServicedetail} />} label="Content Creation" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='digital_strategy_media_buying' checked={serviceavlb.digital_strategy_media_buying} onChange={handleServicedetail} />} label="Digital Strategy & Media Buying" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='social_media_management' checked={serviceavlb.social_media_management} onChange={handleServicedetail} />} label="Social Media Management" />
						</Div>
						<Div className="col-sm-6">

							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='research_development' checked={serviceavlb.research_development} onChange={handleServicedetail} />} label="Research & Development" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='data_mining' checked={serviceavlb.data_mining} onChange={handleServicedetail} />} label="Data Mining" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='lead_generation' checked={serviceavlb.lead_generation} onChange={handleServicedetail} />} label="Lead Generation" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='websites_applications' checked={serviceavlb.websites_applications} onChange={handleServicedetail} />} label="BrandWebsites & Applicationsing" />
						</Div>
						<Div className="col-sm-6">
							<FormControlLabel className='checkbox_div' control={<Checkbox defaultChecked name='advisory_consulting_services' checked={serviceavlb.advisory_consulting_services} onChange={handleServicedetail} />} label="Advisory & Consulting Services" />
						</Div>

					</form>
				</DialogContent>
				<DialogActions>
					{btndisable ? <button className="cs-btn cs-style1" >
						<span>Please wait...</span>
					</button> :
						<button className="cs-btn cs-style1" onClick={handleSubmit}>
							<span>Send Message</span>
							<Icon icon="bi:arrow-right" />
						</button>}
				</DialogActions>
			</BootstrapDialog>
    </>
    
  )
}
