import React from 'react'
import SectionHeading from '../../SectionHeading'
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'
import { useParams, useLocation, Link, Navigate, useNavigate } from 'react-router-dom'
import FaqforCC from '../FaqforCC'
import { accordionDataSocialMediaManagement } from '../../Accordion/AccordionData'
import { Helmet } from "react-helmet";

const SocialMediaManagement = () => {
  const navigate = useNavigate()
    const colordata = useSelector((state) => state && state.colordata)
    const handleClick = () => {
      window.location.href = "https://theimpression.me";
    };
    const handleClick1 = () => {
      navigate(`/service/lead-generation-and-acquisition`, {
        state: { index: 4 },
      });
    };
    return (
      <>
          <Helmet>
                <title>Social Media Management Agency | Social Media Marketing</title>
                <meta
                name="description"
                content="Boost your brand's online presence with expert social media management services by The Impression in Dubai. Engage your audience and drive business growth."
                />
          </Helmet>
        <SectionHeading
          mainTitle="Social Media Management"
          para="In Dubai's fast-paced digital market, social media is vital for businesses to connect with audiences and build loyalty. With the rise of digital marketing in the UAE, platforms like Instagram and TikTok are key to staying competitive. The Impression helps businesses manage and optimize their social media presence, driving engagement and growth in this dynamic region.
          "
          caption="Social Media Management Services for Dubai Businesses"
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              caption="Services Offered by a Social Media Management Agency"
            >
              <div>
                <ul>
                  <li>
                    <h3 className="no-margin">Strategy Development:</h3>Crafting tailored
                    strategies for restaurant customer acquisition, insurance
                    client acquisition, and more.
                  </li>
                  <br></br>
                  <li>
                    <h3 className="no-margin">Content Creation:</h3> Producing engaging posts
                    for platforms like Instagram and LinkedIn, tailored to
                    industries like F&B <Link
                      to="https://theimpression.me"
                      style={{ color: "rgb(238 45 249)", textDecoration: "underline", cursor: "pointer" }}
                       >
                      digital marketing services
                    </Link> and real
                    estate growth campaigns.
                  </li>
                  <br></br>
                  <li>
                    <h3 className="no-margin">Paid Ad Campaigns: </h3> Running targeted PPC
                    for e-commerce websites and Google Ads for medical services
                    to maximize reach.
                  </li>
                  <br></br>
                  <li>
                    <h3 className="no-margin">Analytics and Reporting:</h3> Providing insights
                    into campaign performance for industries such as hospitality{" "}
                    <Link
                      to="/service/lead-generation-and-acquisition"
                      state={{ index: 4 }} // Pass state directly through the `state` prop
                      style={{ color: "rgb(238 45 249)", textDecoration: "underline", cursor: "pointer" }}
                    >
                      digital lead generation
                    </Link>.
                    and education PPC advertising.
                  </li>
                </ul>
              </div>
              <Spacing lg="30" md="20" />

              {/* <h2
                        className="cs-m0"
                        style={{ color: colordata == "light" ? "#000" : "" }}
                      >
                        Driving brand futures through innovation, individuality,
                        and social responsibility.
                      </h2>
                      <Spacing lg="15" md="15" />
                      <p
                        className="cs-m0"
                        style={{ color: colordata == "light" ? "#000" : "" }}
                      >
                        The Impression is at the forefront of digital evolution.
                        We're committed to evolving international businesses,
                        forging impactful partnerships, and delivering growth
                        across all sectors.
                      </p>
                      <Spacing lg="30" md="30" />
                      <Div className="cs-separator cs-accent_bg"></Div>
                      <Spacing lg="25" md="40" /> */}
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>



        <Div className="row">
          <SectionHeading
            caption="Industries We Support with Tailored Content Solutions"
            para="We proudly serve a variety of industries, including insurance, e-commerce, hospitality, real estate, F&B, education, and healthcare. Our expertise spans SEO for insurance brokers, PPC for e-commerce websites, restaurant customer acquisition, and digital campaigns for real estate developers, delivering tailored solutions to meet industry-specific needs."
          ></SectionHeading>
        </Div>

        <Div className="row">
          <SectionHeading caption="Key Benefits of Hiring a Social Media Management Agency">
            <div>
              <ul>
                <li>
                  <h3 className="no-margin">Saves Time and Effort: </h3> Focus on your
                  business while we manage campaigns like insurance Google Ads
                  services and e-commerce advertising campaigns.
                </li>
                <br></br>
                <li>
                  <h3 className="no-margin">Access to Expert Tools:</h3> Leverage platforms
                  for SEO for insurance brokers and PPC advertising for
                  restaurants.
                </li>
                <br></br>
                <li>
                  <h3>Scales Quickly:</h3> Launch campaigns for
                  hospitality growth ads or seasonal restaurant retargeting
                  campaigns efficiently.
                </li>
                <br></br>
                <li>
                  <h3 className="no-margin">Localized Approach:</h3> Tailored strategies for
                  Dubai’s market, such as F&B <span
                    style={{ color: "rgb(238 45 249)", textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    digital marketing services
                  </span>{" "} and
                  real estate marketing services.
                </li>
              </ul>
            </div>
          </SectionHeading>
        </Div>

        <Spacing lg="100" md="80" />

        
        <>
          {/* <SectionHeading subtitle="Social Media Management FAQs for Businesses in Dubai"></SectionHeading>
          <Div className="row">
            <Div
              className={
                accordionDataSocialMediaManagement.length > 7
                  ? "col-xl-5 col-lg-6"
                  : ""
              }
            >
              <Accordion name="SocialMediaManagement" />
            </Div>
            {accordionDataSocialMediaManagement.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="SocialMediaManagement" isSecondColumn={true} />
              </Div>
            )}
          </Div> */}
        </>
      </>
    );

}

export default SocialMediaManagement;



{/* <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <Div className="cs-image_layer cs-style1">
                <Div className="cs-image_layer_in">
                  <img
                    src="/images/about_img_4.jpeg"
                    alt="About"
                    className="w-100 cs-radius_15"
                  />
                </Div>
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-xl-5 offset-xl-1 col-lg-6">
              <SectionHeading title="Why Partner with Us?" subtitle="">
                <Spacing lg="30" md="20" />

                <p
                  className="cs-m0"
                  style={{ color: colordata == "light" ? "#000" : "" }}
                >
                  Our approach is holistic and data-driven, ensuring that every
                  strategy is grounded in real-world insights and tailored to
                  your specific goals. We're not just consultants; we're your
                  strategic partners, dedicated to navigating the complexities
                  of the digital landscape together.
                </p>
                <Spacing lg="15" md="15" />
                <p
                  className="cs-m0"
                  style={{ color: colordata == "light" ? "#000" : "" }}
                >
                  With us, you gain more than just a service provider; you gain
                  allies committed to your success. Our track record speaks for
                  itself: a history of transforming challenges into
                  opportunities and businesses into benchmarks of their
                  industries.
                </p>
                <Spacing lg="15" md="15" />
                <p style={{ color: colordata == "light" ? "#000" : "" }}>
                  Choose us and let's contribute to your Long and Lasting
                  IMPRESSION.
                </p>
                <Spacing lg="30" md="30" />
                <Div className="cs-separator cs-accent_bg"></Div>
                <Spacing lg="25" md="0" />
              </SectionHeading>
            </Div>
          </Div>
        </Div> */}
