// accordionData.js

const accordionDataSocialMediaManagement = [
    {
        question: 'Why Should I Hire a Social Media Management Agency?',
        answer: 'Agencies like The Impression save you time and deliver results with strategies like insurance client acquisition and real estate promotion services.'
    },
    {
        question: 'How Long Does It Take to See Results?',
        answer: 'Results vary, but campaigns like PPC for e-commerce websites or SEO for restaurants typically show impact within weeks.'
    },
    {
        question: 'Do Social Media Agencies Help with Paid Advertising?',
        answer: 'Yes, we run targeted campaigns like Google Ads for medical services and insurance PPC campaigns to boost visibility.'
    },
    {
        question: 'How Much Does Social Media Management Cost?',
        answer: 'Costs depend on scope, including services like restaurant branding strategy or hospitality digital lead generation. Contact us for details.'
    }
];

const accordionDataDigitalMedia = [
    {
        question: 'What is a digital strategy, and why is it important for my business?',
        answer: 'It’s a plan to use digital channels effectively. We specialize in strategies for industries like real estate and e-commerce to achieve results.'
    },
    {
        question: 'What is media buying, and how does it work?',
        answer: 'It’s purchasing ad space on platforms like Google and TikTok. We manage campaigns for PPC advertising and insurance Google Ads services.'
    },
    {
        question: 'Which platforms do you specialize in for media buying?',
        answer: 'We excel in Google, Meta, TikTok, and LinkedIn, with expertise in Google Ads for medical services and PPC for e-commerce websites.'
    },
    {
        question: 'Can you help with both B2B and B2C campaigns?',
        answer: 'Yes, we handle B2B SaaS ads and B2C hospitality campaigns effectively.'
    },
    {
        question: 'Do you manage budgets for media buying campaigns?',
        answer: 'Yes, we optimize budgets for e-commerce growth campaigns and restaurant branding strategies to maximize ROI.'
    },
    {
        question: 'Do you provide support after campaign launch?',
        answer: 'Yes, we offer post-launch support, including performance tracking and real-time optimization.'
    },
    {
        question: 'Can you help with audience targeting for campaigns?',
        answer: 'Yes, we specialize in audience segmentation for real estate lead generation and F&B digital marketing services.'
    },
    {
        question: 'How do you track the success of campaigns?',
        answer: 'We use advanced tools to monitor metrics like ROI and conversions for insurance PPC campaigns and e-commerce advertising campaigns.'
    },
    {
        question: 'Do you work with small businesses?',
        answer: 'Absolutely! We create tailored strategies for startups and SMBs in industries like education PPC advertising and restaurant customer acquisition.'
    },
    {
        question: 'How quickly can you launch a campaign?',
        answer: 'We can launch campaigns promptly, including Google Ads for real estate developers and hospitality digital lead generation, often within days after planning.'
    }
];

const accordionDataContentCreation = [
    {
        question: 'Why should I hire a content creation agency in Dubai?',
        answer: 'We deliver high-quality, targeted content and are trusted by high-end clients in industries like healthcare and real estate.'
    },
    {
        question: 'What industries do you work with?',
        answer: 'We work with insurance, hospitality, real estate, F&B, healthcare, education, and more.'
    },
    {
        question: 'What types of content do you create?',
        answer: 'We create videos, photography, blogs, ad copies, and social media content.'
    },
    {
        question: 'How long does it take to deliver a project?',
        answer: 'Timelines vary depending on project size, contact us for more info.'
    },
    {
        question: 'Do you provide content in multiple languages?',
        answer: 'Yes, we offer content in English, Arabic, and other languages.'
    },
    {
        question: 'What is your pricing structure?',
        answer: 'Our pricing is customized based on project scope and requirements. Contact us for details.'
    },
    {
        question: 'Can you help improve my website’s SEO rankings?',
        answer: 'Yes, we specialize in SEO for insurance websites, e-commerce platforms, and other industries to boost search engine visibility.'
    },
    {
        question: 'Do you offer social media management services?',
        answer: 'Yes, we manage platforms like Instagram, LinkedIn, and TikTok, creating engaging content tailored for F&B and hospitality industries.'
    },
    {
        question: 'Can you handle large-scale projects?',
        answer: 'Absolutely, we have experience managing large campaigns like real estate lead generation and healthcare brand awareness ads.'
    },
    {
        question: 'Do you offer revisions to the content?',
        answer: 'Yes, we provide revisions to ensure the content aligns with your vision and goals.'
    },
    {
        question: 'Do you create content for specific campaigns like PPC or SEO?',
        answer: 'Yes, we specialize in content for PPC advertising for insurance agencies, Google Ads for real estate, and SEO for online stores.'
    },
    {
        question: 'Can you help with branding and design?',
        answer: 'Yes, we offer branding, infographics, and marketing materials tailored for industries like F&B and education.'
    },
    {
        question: 'Do you provide analytics or reporting for campaigns?',
        answer: 'Yes, we deliver detailed insights to measure the performance of e-commerce advertising campaigns and hospitality growth strategies.'
    },
    {
        question: 'Do you collaborate with in-house teams?',
        answer: 'Absolutely, we seamlessly work with your team to align our strategies with your goals for digital ads for tourism or restaurant customer acquisition.'
    },
    {
        question: 'What is your process for creating content?',
        answer: 'Our process involves research, strategy, creation, and optimization to ensure high-quality results for real estate promotion services and other industries.'
    }
];


const accordionData360Campaign = [
    {
        question: 'What is a 360° campaign?',
        answer: 'A 360° campaign is an integrated marketing approach that uses multiple channels—like social media, email, PPC, and SEO—to create a cohesive and impactful brand experience.'
    },
    {
        question: 'How does a 360° campaign benefit my business?',
        answer: 'It enhances brand visibility, ensures consistent messaging across platforms, and increases engagement, ultimately driving better ROI.'
    },
    {
        question: 'Which industries are best suited for 360° campaigns?',
        answer: 'Industries like real estate, hospitality, e-commerce, healthcare, and F&B benefit greatly from cohesive, multi-channel campaigns.'
    },
    {
        question: 'How long does it take to execute a 360° campaign?',
        answer: 'Timelines vary based on scope, but typically, campaigns take 4-8 weeks from strategy development to execution.'
    },
    {
        question: 'Can you customize campaigns to fit my brand’s needs?',
        answer: 'Absolutely! Our campaigns are tailored to your goals, audience, and industry-specific requirements.'
    },
    {
        question: 'Do you provide post-campaign performance reports?',
        answer: 'Yes, we deliver detailed analytics and insights to track success and refine strategies for future campaigns.'
    }
];

const accordionDataLeadGeneration = [
    {
        question: 'What is lead generation?',
        answer: 'Lead generation involves capturing potential customers\' interest to drive sales and business growth.'
    },
    {
        question: 'How do you ensure the quality of leads?',
        answer: 'We use advanced buyer intent research and analytics to target high-quality prospects.'
    },
    {
        question: 'Which industries do you specialize in?',
        answer: 'Our expertise spans insurance, e-commerce, hospitality, healthcare, and more.'
    },
    {
        question: 'Can you integrate campaigns with my existing marketing efforts?',
        answer: 'Absolutely! We seamlessly align our strategies with your current marketing channels.'
    },
    {
        question: 'What platforms do you use for lead generation?',
        answer: 'We leverage Google Ads, social media, email marketing, and SEO for multi-channel campaigns.'
    },
    {
        question: 'Do you provide ongoing support after lead acquisition?',
        answer: 'Yes, we offer continuous campaign refinement and performance tracking to maximize results.'
    }
];

const accordionDataRDInDigitalMarketing = [
    {
        question: 'What does R&D involve in digital marketing?',
        answer: 'It includes trend analysis, consumer behavior research, and the integration of technological advancements to inform strategies.'
    },
    {
        question: 'How does R&D benefit my business?',
        answer: 'R&D helps identify opportunities, enhance customer engagement, and maintain competitive relevance in a dynamic market.'
    },
    {
        question: 'Which industries benefit from your R&D services?',
        answer: 'Industries like real estate, e-commerce, F&B, healthcare, and hospitality leverage our R&D insights to stay ahead.'
    },
    {
        question: 'Do you offer customized R&D solutions?',
        answer: 'Yes, our research is tailored to your business goals and market dynamics.'
    },
    {
        question: 'How do you use technology in R&D?',
        answer: 'We integrate AI, machine learning, and data analytics to extract actionable insights and refine strategies.'
    },
    {
        question: 'Can R&D help with future-proofing my business?',
        answer: 'Absolutely! Our R&D ensures your strategies adapt to emerging trends and evolving consumer expectations.'
    }
];

const accordionDataCharacterCreation = [
    {
        question: 'What is character creation in marketing?',
        answer: 'It involves designing 2D or 3D characters to enhance storytelling and build stronger audience connections.'
    },
    {
        question: 'How can characters help my brand?',
        answer: 'Characters add personality to your messaging, improve engagement, and boost brand recall.'
    },
    {
        question: 'Which industries benefit from character creation?',
        answer: 'E-commerce, hospitality, gaming, and entertainment industries often use characters to captivate audiences.'
    },
    {
        question: 'Can characters be tailored to my brand’s identity?',
        answer: 'Absolutely! We create characters that align with your brand’s voice, values, and objectives.'
    },
    {
        question: 'What is the difference between 2D and 3D characters?',
        answer: '2D characters are flat, visually dynamic designs, while 3D characters are lifelike, fully dimensional models used in animations or interactive media.'
    },
    {
        question: 'Do you provide animation services for characters?',
        answer: 'Yes, we offer animation for both 2D and 3D characters to bring them to life across digital platforms.'
    }
];

const accordionDataBranding = [
    {
        question: 'What is branding, and why is it important?',
        answer: 'Branding defines your business’s identity, helping you stand out and connect emotionally with your audience.'
    },
    {
        question: 'What does your branding service include?',
        answer: 'It includes strategic positioning, visual identity design, messaging development, and brand guidelines.'
    },
    {
        question: 'Can you redesign an existing brand?',
        answer: 'Yes, we offer rebranding services to refresh and modernize your brand’s identity.'
    },
    {
        question: 'Which industries can benefit from branding?',
        answer: 'Industries like real estate, F&B, healthcare, and technology often require strong branding to succeed.'
    },
    {
        question: 'How long does the branding process take?',
        answer: 'Timelines vary, but we typically deliver comprehensive branding within 4-8 weeks.'
    },
    {
        question: 'Do you provide branding for startups?',
        answer: 'Absolutely! We specialize in crafting impactful identities for startups and growing businesses.'
    }
];

const accordionDataProgrammaticAdvertising = [
    {
        question: 'What is programmatic advertising?',
        answer: 'It automates the buying and placement of ads using real-time bidding to target specific audiences.'
    },
    {
        question: 'Which platforms are supported for programmatic advertising?',
        answer: 'Platforms include websites, mobile apps, social media, and video platforms.'
    },
    {
        question: 'How does programmatic advertising benefit my business?',
        answer: 'It ensures ads are delivered to the right audience at the right time, optimizing ROI and engagement.'
    },
    {
        question: 'Can you target specific audiences with programmatic advertising?',
        answer: 'Yes, advanced algorithms allow for precise audience targeting based on demographics, interests, and behaviors.'
    },
    {
        question: 'Which industries are best suited for programmatic advertising?',
        answer: 'Real estate, e-commerce, healthcare, and hospitality are among the industries that see significant benefits.'
    },
    {
        question: 'How do you measure the success of a programmatic campaign?',
        answer: 'Success is tracked through metrics like impressions, click-through rates, and conversions using advanced analytics tools.'
    }
];

const accordionDataUIUXDesign = [
    {
        question: 'What is the importance of UI/UX design for my business?',
        answer: 'It enhances user satisfaction, builds brand loyalty, and improves conversion rates by delivering seamless experiences.'
    },
    {
        question: 'Can you design for both websites and mobile applications?',
        answer: 'Yes, we specialize in responsive designs that ensure consistency across all devices.'
    },
    {
        question: 'Which industries benefit from UI/UX design?',
        answer: 'Industries like e-commerce, healthcare, real estate, and education see significant advantages.'
    },
    {
        question: 'How long does it take to design a website or app?',
        answer: 'Timelines depend on project complexity but typically range from 4-8 weeks.'
    },
    {
        question: 'Do you provide prototyping and testing services?',
        answer: 'Yes, we validate designs through user testing and refine them for optimal functionality.'
    },
    {
        question: 'Can you update my existing website or application?',
        answer: 'Absolutely! We can redesign and optimize your platform to enhance user experience and performance.'
    }
];

const accordionDataDataMining = [
    {
        question: 'What is data mining, and how can it help my business?',
        answer: 'Data mining uncovers patterns and trends in large datasets, helping you make informed decisions and refine strategies.'
    },
    {
        question: 'What industries benefit most from data mining?',
        answer: 'Industries like healthcare, real estate, e-commerce, and insurance gain significant advantages from actionable insights.'
    },
    {
        question: 'How do you ensure data privacy and security?',
        answer: 'We follow strict compliance protocols to safeguard your data throughout the process.'
    },
    {
        question: 'Can you customize data mining reports for my industry?',
        answer: 'Yes, we tailor reports to meet the unique needs and goals of your business.'
    },
    {
        question: 'How long does it take to complete a data mining project?',
        answer: 'Timelines vary based on the complexity and volume of data but typically range from 2-6 weeks.'
    },
    {
        question: 'What tools do you use for data mining?',
        answer: 'We use advanced analytics platforms and visualization tools to deliver accurate and actionable insights.'
    }
];

const accordionDataAdvisoryConsulting = [
    {
        question: 'What is advisory consulting, and how does it help?',
        answer: 'Advisory consulting provides expert guidance to improve processes, infrastructure, and engagement, driving growth and efficiency.'
    },
    {
        question: 'Which industries can benefit from your advisory consulting?',
        answer: 'We work across sectors like government, healthcare, education, technology, and more.'
    },
    {
        question: 'Can you customize consulting solutions for my needs?',
        answer: 'Yes, our strategies are tailored to address the unique goals and challenges of your organization.'
    },
    {
        question: 'How do you ensure your advice stays relevant to market trends?',
        answer: 'Our insights are rooted in extensive research and the latest digital trends.'
    },
    {
        question: 'What is the typical duration of an advisory consulting project?',
        answer: 'Project timelines vary but typically range from 4-12 weeks, depending on complexity.'
    },
    {
        question: 'Do you offer post-project support?',
        answer: 'Yes, we provide ongoing support to help you implement and refine our recommended strategies.'
    }
];


const accordionDataLeadForge = [
    {
        question: 'What is LeadForge, and how does it work?',
        answer: 'LeadForge is a lead generation tool that combines data mining, email marketing, and cold calling to identify and engage high-value prospects.'
    },
    {
        question: 'Can LeadForge be customized for my business?',
        answer: 'Yes, LeadForge is tailored to fit your industry, target audience, and business goals.'
    },
    {
        question: 'Which industries benefit most from LeadForge?',
        answer: 'Industries like real estate, e-commerce, insurance, and technology gain significant advantages with our targeted strategies.'
    },
    {
        question: 'How does LeadForge optimize conversion rates?',
        answer: 'By combining buyer intent research with precise targeting, LeadForge ensures meaningful engagement with potential leads, driving higher conversions.'
    },
    {
        question: 'Does LeadForge provide reporting and analytics?',
        answer: 'Yes, detailed performance metrics are included to help track, refine, and optimize your lead generation strategies.'
    },
    {
        question: 'What makes LeadForge different from other tools?',
        answer: 'Its integration of advanced data mining, communication strategies, and a corporate lead database makes it uniquely effective for generating high-quality leads.'
    }
];









// Export the data
export {
    accordionDataSocialMediaManagement,
    accordionDataDigitalMedia,
    accordionDataContentCreation,
    accordionData360Campaign,
    accordionDataLeadGeneration,
    accordionDataRDInDigitalMarketing,
    accordionDataCharacterCreation,
    accordionDataBranding,
    accordionDataProgrammaticAdvertising,
    accordionDataUIUXDesign,
    accordionDataDataMining,
    accordionDataAdvisoryConsulting,
    accordionDataLeadForge
};
