import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Team from '../Team';
import axios from 'axios';
import { Helmet } from "react-helmet";

export default function TeamPage() {
  pageTitle('Team');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getTeamMembers()
  }, [])

  const [teamList, setTeamList] = React.useState([])
  const getTeamMembers = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `team/getTeamPublic`).then((res) => {
      setTeamList(res.data.teamdata)
    }).catch((error) => {
      console.log(error, "error");
    });
  };

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/team_hero_bg.jpeg"
        pageLinkText="Team"
      />
       <Helmet>
              <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      <Spacing lg="145" md="80" />
      {teamList && teamList.length > 0 ?
      <Div className="container">
        <SectionHeading
          title="Meet our awesome <br/>team members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row team_grid" >
          {teamList && teamList.map((item, index) => (
            <Div key={index} className="col-lg-3 col-sm-6">
              <Team
               memberImage={item.Image}
               memberName={item.firstname}
               memberDesignation={item.role}
               memberLinkedIn={item.linkedin}
               memberFacebook={item.facebook}
               memberInstagram={item.instagram}
               memberTwitter={item.twitter}
              />
              <Spacing lg="80" md="30" />
            </Div>
          ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
        <Cta
          // title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
          title="Let’s Create <br />something Remarkable!"
          btnText="Book a Meeting Now"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
        </Div>
      </Div> : <>
            <h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No team member found</h2>
            <Spacing lg="90" md="45" />
          </>}
    </>
  );
}
